import { useNavigate, Link, useLocation } from "react-router-dom";
import { AiOutlineInfoCircle, AiOutlineProfile, AiFillCheckCircle } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import { displayDate, copyToClipboard } from "../utilities/functions";
import { ProcessTemplate } from "../utilities/templates";
import DropdownBenSearch from "./DropdownSearchBen";
import { GetVisitLabelByToken } from "../utilities/calls";
import { useState } from "react";
import QRCodeModal from "./QRCodeModal";
import { FaLink } from "react-icons/fa";


export default function ContentBox({ template, content, onDelete, ben, message, onConnect, sharedTo, benList, connected, serviceProviderId, benId, sentBadge, from, to, created, updated, requestId, isBusiness, visitToken }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [visitLink, setVisitLink] = useState();
  const [qrcode, setQrcode] = useState();
  const handleOpenModal = () => {
    if (visitLink) {
      setQrcode(visitLink);
      setModalOpen(true);
    }
  }
  const handleCloseModal = () => setModalOpen(false);

  const fetchServiceProviderDetails = (serviceProviderID) => {
    if (serviceProviderID !== null && serviceProviderID !== undefined) {
      navigate(`/AddServiceProviderProfile/${serviceProviderID}`, { state: { readOnly: true, showPrivate: false, previousPage: pathname } })
    }
  }
  const fetchBenDetails = (benId) => {
    if (benId !== null && benId !== undefined) {
      navigate(`/AddBeneficiaryProfile/${benId}`, { state: { readOnly: true, showPrivate: false, previousPage: pathname } })
    }
  }

  const fetchBeneficiaryDetails = (benId) => {
    if (benId !== null && benId !== undefined) {
      navigate(`/AddBeneficiaryProfile/${benId}`, { state: { readOnly: true, showPrivate: true, previousPage: pathname } })
    }
  }

  const templatedetails = ProcessTemplate(template);
  //  { contentTypeTag, templateName: templatename, contentNameTag, tags: template.Tags }

  const getFields = () => {
    let fields = [];
    if (templatedetails?.tags) {
      fields = templatedetails?.tags;
    }
    return fields
  }

  const handleGetVisitLink = (e) => {

    GetVisitLabelByToken(visitToken).then((resp) => {
      let visitlink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp;
      setVisitLink(visitlink);
    }
    ).catch((err) => console.log('err', err));
  }


  return (
    content ?
      <div className="relative md-container border border-gray-500 bg-gray-800 rounded m-1 pt-6 pb-12 px-3" style={{ "overflow-wrap": "break-word" }}>
        <div className="flex flex-row justify-between">
          <p className="font-bold leading-6 text-white flex flex-row justify-around">
            <Link class="font-semibold cursor-pointer" to={`/addsecurecontent/${content.id}`} state={{ "contenttype": templatedetails.templateName, "template": template, "readonly": true, "previousPage": pathname, "content": content }} >
              {content?.tags[templatedetails.contentNameTag]?.Value}</Link>
          </p>
          <p>
            {onDelete ?
              <button formMethod="dialog" formTarget="top" id="deleterequest" className="bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
                <BsFillTrash3Fill title="Delete" className="inline-icon" />
              </button>
              : <></>}
          </p>
        </div>
        <p className="text-xs text-gray-200">{content?.id}</p>
        <p><span class="text-xs font-medium me-2 text-blue-300 "><AiOutlineProfile className='inline-icon' style={{ height: "1rem", width: "1rem" }} />{templatedetails.templateDisplayName}</span>          </p>
        <p>{sentBadge ? <span class="text-xs text-center px-1 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400">content Sent</span> : <></>}
        </p>
        <p className="mt-1 text-xs text-gray-400">{updated ? "Updated " + displayDate(updated) : ""}  </p>
        {updated && created && updated !== created ? <p className="text-xs text-gray-400">{created ? "Created " + displayDate(created) : ""}</p> : <></>}
        {Object.values(getFields()).map((key) => {
          return <p className="mt-2  text-sm leading-5 text-gray-100">
            <span className="italic text-xs text-gray-400">{key.Name ? key.Name : ""} </span> <div> {(key.Name !== templatedetails.contentNameTag ? content.tags[key.Name]?.Value : "")} </div>
          </p>
        })}
        {from && from !== "" ?
          <p className="mt-2 leading-6 text-gray-100">
            <span className="text-xs ">Shared By: </span>
            <span className="text-xs  text-blue-400">{from}</span>
          </p> : <></>
        }
        {to && to !== "" ? <>
          <p className=" leading-6 text-gray-100">
            <span className="text-xs">Shared With: </span>
            <span className="text-xs text-blue-400">{to}</span>
          </p> {visitToken ? <>
            {visitLink === undefined ? <button formMethod="dialog" formTarget="top" id="visitLink" className="mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={handleGetVisitLink}>
              <FaLink title="Get Visit Link" className="inline-icon" />Generate Link</button> : <></>}
              <div>
              {visitLink && (
                <>
                  <p class="mt-1 italic text-gray-200 text-xs">Link is generated, valid for 24 hours.</p>
                  <button onClick={() => { copyToClipboard(visitLink); document.getElementById("checked_" + content.id).style.display = ""; }}
                    className="mt-1 mr-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500">
                    Copy link
                  </button>
                  <span className="text-sm text-green-300" id={"checked_" +content.id} style={{ display: "none" }} >
                    <AiFillCheckCircle color="green" style={{ "padding-left": "0.1rem" }} className=" inline-icon" />Copied to clipboard.
                  </span>
                  <button className="mt-1 items-center inline-flex justify-center px-2 space-x-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={handleOpenModal}>Show QR Code</button>
                  {isModalOpen && (
                    <QRCodeModal
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      qrValue={qrcode}
                    />
                  )}
                </>
              )}
			   </div>
                </>
              : <></> }</>  : <></>
        }
        {sharedTo && sharedTo.length > 0 ?
          <p className=" leading-6 text-gray-100">
            <span className="text-xs">Shared With: </span>
            {
              ben ? (<button className="text-xs text-blue-400" onClick={() => fetchServiceProviderDetails(serviceProviderId)}>{sharedTo}</button>)
                : (<button className="text-xs text-blue-400" onClick={() => fetchBenDetails(benId)}>{sharedTo}</button>)
            }
          </p> : <></>
        }
        {
          requestId && ben.length > 0 ? (<div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
            <div class="flex">
              <p class=" text-sm">Your recommendation was accepted. <span class="text-blue-400">{ben.map((b) => { return <button onClick={() => fetchBeneficiaryDetails(b.beneficiaryID)}>{"  " + benList.find(bn => bn.id === b.beneficiaryID)?.tags?.name?.Value + " "}</button> })} (Beneficiary)</span> is now a connection!</p></div></div>
          ) : (requestId ? <DropdownBenSearch requestId={requestId} benList={benList} /> : <></>)
        }
        {
          !isBusiness && ben ?
            ben.map((id) => {

              return <div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
                <div class="flex">
                  {connected ? <div class="py-2 text-sm "> <span className="font-semibold ">Great! </span><div>You are connected with {benList[id]?.tags?.name?.Value + " (beneficiary)"} recommended by {sharedTo + " (Service Provider)"}.</div></div>
                    :
                    <div class="ms-1 text-sm font-normal">
                      <span class="mb-1 text-sm font-semibold text-white"><AiOutlineInfoCircle className="inline-icon" /> Recommendation! </span>
                      <div class="py-2 text-xs">{sharedTo} has referred {benList[id]?.tags?.name?.Value} to you.</div>
                      <blockquote class="text-xs italic font-semibold text-white">
                        <p>{message && message.length > 0 ? '"' + message + '"' : <></>}</p>
                      </blockquote>
                      <div class="grid grid-cols-2 gap-2 p-2">
                        <div>
                          <Link to={`/AddBeneficiaryProfile/${id}`} state={{ readOnly: true, showPrivate: false, previousPage: pathname }} >
                            <button class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">View</button></Link>
                        </div>
                        <div>
                          <button onClick={onConnect} class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">Connect</button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>


            }) : <></>
        }
      </div>
      : <></>);
}