import DeleteModal from './DeleteModal';
import React, { useEffect, useState } from 'react';
import { GetGlobalTemplatesList, getInfo, GetSecuredContents, DeleteSecureContent, GetSharesForSecureContent, GetTemplatesByServiceProviderID, GetTemplateByID, GetServiceProvider, GetBeneficiary, FetchIndividual, GetSharesForAddress, DeleteAddress } from "../utilities/calls";
import { useNavigate } from "react-router-dom";
import { displayDate, getSharesWith } from "../utilities/functions";
import { ProcessTemplate, FirstMatchedTemplate } from "../utilities/templates";
import ShareModal from "./ShareModal";
import ContentList from "./ContentList";

const defaultSP = getInfo('DefaultServiceProvider');
let timer = undefined;
const individualid = getInfo("IndividualID");
let spIds = [];

export default function SecureContentList() {
    const navigate = useNavigate();
    const [securedcontents, setSecuredcontents] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currSpId, setCurrSpId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState();
    const [selectedContentTitle, setSelectedContentTitle] = useState();
    const [sharesWith, setSharesWith] = useState({});
    const [spDetails, setSpDetails] = useState({});
    const [benDetails, setBenDetails] = useState({});
    const [individual, setIndividual] = useState({});
    const [addrSharedCounts, setAddrSharedCounts] = useState({});
    const [addrSharesWith, setAddrSharesWith] = useState({});
    const [selectedAddress, setSelectedAddress] = useState();
    const [currAddressId, setCurrAddressId] = useState();
    const [defaultTemplates, setDefaultTemplates] = useState([{
        Name: "address_locales", Tags: {
            "DisplayName": {
                "Name": "DisplayName",
                "Value": "Addresses",
                "Private": false,
                "Required": true,
                "Editable": false
            }
        }
    }]);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        GetAddressses();
        if (defaultSP) {
            GetTemplatesByServiceProviderID(defaultSP).then((templateIdsArr) => {
                templateIdsArr.forEach((id) => {
                    GetTemplateByID(id).then((template) => { setDefaultTemplates(prev => [...prev,template]) }).catch(err => console.log('Error fetching templates for spid ', id, err));
                })
            }).catch(err => console.log('Error fetching templates for spid ', err));
        }
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        LoadList();
    },[]);

    useEffect(() => {
       
        const matchGlobalList = (spid, newtemplates) => {
           newtemplates.forEach((id) => {
                GetTemplateByID(id).then((gt) => {
                    securedcontents.forEach((sc, index) => {
                        let newmatch = FirstMatchedTemplate(sc, [gt]);
                        if (newmatch) {
                            setTemplates(prev => [...prev, newmatch])
                            return
                        }
                    })
                }).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
            })

        }

        GetGlobalTemplatesList("", matchGlobalList);
    }, [securedcontents]);

    const getTemplates = (spid) => {
        if (spid === defaultSP)
            return;
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((template) => setTemplates(prev => [...prev, template])).catch(err => console.log('Error fetching templates for spid ', spid, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }

    const LoadList = () => {
        GetSecuredContents()
            .then((resp) => {
                let referredSpIds = [];
                let referredBenIds = [];
                setSecuredcontents(resp);
                const fetchCounts = async (contents) => {
                    const sharesPromises = contents.map((c) =>
                        GetSharesForSecureContent(c.id)
                            .then((response) => {
                                response.forEach(share => {
                                    getTemplates(share.serviceProviderID);
                                    if (spIds.findIndex((v) => v === share.serviceProviderID) === -1) {
                                        spIds.push(share.serviceProviderID); 
                                    }
                                });
                                let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
                                referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({ ...benList, [benId]: el }))).catch((err) => console.log(err)))
                                referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({ ...spList, [spId]: el }))).catch((err) => console.log(err)))

                                return { securedcontentid: c.id, count: response.length, sharesWith: sharesWithArr };
                            })
                            .catch((error) => {
                                console.error(`Error shares for ID ${c.id}:`, error);
                                setShowAlert(true);
                                setAlertText("Error fetching shares");
                            })
                    );
                    Promise.all(sharesPromises)
                        .then((details) => {
                            const cmap = {};
                            const sharesMap = {};
                            details.forEach(
                                (detail) => {
                                    cmap[detail.securedcontentid] = detail.count;
                                    sharesMap[detail.securedcontentid] = detail.sharesWith;
                                })
                            setSharedCounts(cmap);
                            setSharesWith(sharesMap);
                        })
                        .catch((error) => {
                            console.error("Error setting record details:", error);
                            setShowAlert(true);
                            setAlertText("Error setting record details");
                        });
                };
                if (resp && resp.length > 0) {
                    fetchCounts(resp);
                }
            })
            .catch((error) => {
                console.error("Error in getSecureContent:", error);
                setShowAlert(true);
                setAlertText('Error loading data, please try again')
            });
    }

    const GetAddressses = () => {

        FetchIndividual(individualid).then((individualData) => {
            let referredSpIds = [];
            let referredBenIds = [];

            // Set the retrieved individual in the state
            const alladdresses = individualData?.addresses || {};
            const fetchCounts = async (addresses) => {
                const sharesPromises = Object.keys(addresses).map((id) =>
                    GetSharesForAddress(id)
                        .then((response) => {
                            let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
                            referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({ ...benList, [benId]: el }))).catch((err) => console.log(err)))
                            referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({ ...spList, [spId]: el }))).catch((err) => console.log(err)))

                            return { addressid: id, count: response.length, sharesWith: sharesWithArr };

                        })
                        .catch((error) => {
                            console.error(`Error shares for ID ${id}:`, error);
                            setShowAlert(true);
                            setAlertText("Error fetching shares");
                        })
                );
                Promise.all(sharesPromises)
                    .then((details) => {
                        const cmap = {};
                        const sharesMap = {};
                        details.forEach(
                            (detail) => {
                                cmap[detail.addressid] = detail.count;
                                sharesMap[detail.addressid] = detail.sharesWith
                            }
                        );
                        setAddrSharedCounts(cmap);
                        setAddrSharesWith(sharesMap);

                    })
                    .catch((error) => {
                        console.error("Error setting record details:", error);
                        setShowAlert(true);
                        setAlertText("Error setting record details");
                    });
            };
            setIndividual(individualData);
            if (Object.keys(individualData?.addresses || {}).length > 0) {
                fetchCounts(alladdresses);
            }
        });

    }


    const handleDeleteClick = (id, template) => {
        if (template.Name === "address_locales") {
            setCurrAddressId(id);
            setCurrSpId();
        } else {
            setCurrSpId(id);
            setCurrAddressId();
        }
        setShowDeleteDlg(true);
    }

    const deleteSC = () => {
        setShowDeleteDlg(false);
        if (currAddressId) {
            const ownertoken = getInfo("Ownertoken");


            DeleteAddress(currAddressId, individualid, ownertoken)
                .then((response) => {
                    setShowAlert(true);
                    setAlertText("Address was successfully deleted.");
                    timer = setTimeout(() => {
                        setShowAlert(false);
                    }, 2000);
                    GetAddressses();
                })
                .catch((error) => {
                    console.error("Error deleting an address", error);
                    setShowAlert(true);
                    setAlertText("Error deleting an address.");
                });

        } else {
            DeleteSecureContent(currSpId, true).then(() => {
                setShowAlert(true);
                setAlertText("Secured Content was successfully deleted.");
                //setTemplates([])
                templates.length = 0;
                LoadList();
                timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            });
        }
    }

    const handleShareClick = (id, title, template) => {
        if (template.Name === "address_locales") {
            setSelectedAddress(individual.addresses[id]);
            setSelectedContent();
        } else {
            setSelectedContent(securedcontents.find(sc => sc.id === id));
            setSelectedAddress();
        }
        setSelectedContentTitle(title);
        setShowShareModal(true);
    }

    const handleCreateNew = (template) => {
        if (template.Name === "address_locales") {
            navigate("/AddAddressDetails", { state: { tags: individual.tags, previousPage: "/securedContents", "contenttype": template.Name } })
        } else {
            navigate(`/addsecurecontent/`, { state: { "contenttype": (template?.Name), "template": template } })
        }
    }

    const handleViewClick = ( id, template) => {
        if (template.Name === "address_locales") {
            navigate(`/address-details/${id}`, { state: { "contenttype": template.Name, previousPage: '/securedContents' } });
        } else {
            navigate(`/addsecurecontent/${id}`, { state: { "contenttype": (template?.Name), "template": template } })
        }
    }
    let types = [];
    defaultTemplates.forEach((dt) => {
        const isPresent = types.find(type => type.Name === dt.Name)
        if(!isPresent) {
            dt.label = "Default";
            types.push(dt);
        }
    })
    templates.forEach((t) => {
        const isPresent = types.find(type => type.Name === t.Name)
        if(!isPresent) {
            types.push(t);
        }
    })

    const groupedRecords = [];
    const matched = [];

    
    types.forEach((template) => {

        const processedTemplate = ProcessTemplate(template)
        const tag = processedTemplate.contentTypeTag;
        const name = processedTemplate.contentNameTag
        const filteredSC = securedcontents.filter(a => a?.tags[tag]?.Value === template.Name);
        groupedRecords[template.Name] = filteredSC.map((sc) => {
            matched.push(sc.id);
            return {
                id: sc.id,
                title: sc?.tags[name]?.Value || sc?.tags["name"]?.Value || "",
                description: sc.description,
                shares: sharedCounts[sc.id],
                sharedWith: sharesWith[sc?.id],
                created: displayDate(sc.created),
                updated: displayDate(sc.updated),
                type: template.Name
            }
        })

    })

    if (Object.keys(individual?.addresses || {}).length > 0) {
        groupedRecords["address_locales"] = Object.values(individual.addresses).map((addr) => {
            return {
                id: addr.id,
                title: addr?.tags?.atag?.Name || "",
                description: addr.description,
                shares: addrSharedCounts[addr.id],
                sharedWith: addrSharesWith[addr?.id],
                type: "Addresses"
            }
        })
    }

    let unmatched = [];
    securedcontents.forEach((sc) => {
        
        const isPresent = matched.find(m => m === sc.id)
        if(!isPresent) {
            unmatched.push({
                id: sc.id,
                title: sc?.tags["name"]?.Value || "",
                description: sc.description,
                shares: sharedCounts[sc.id],
                sharedWith: sharesWith[sc?.id],
                created: displayDate(sc.created),
                updated: displayDate(sc.updated),
                type: "General"
            })
        }
    })

    if(unmatched.length > 0) {
        groupedRecords["General"] = unmatched;
        types.push({
            Name: "General", Tags: {
                "DisplayName": {
                    "Name": "DisplayName",
                    "Value": "General",
                    "Private": false,
                    "Required": true,
                    "Editable": false
                }
            }
        })
    }

    types = types.sort((a, b) => { return groupedRecords[b.Name]?.length - groupedRecords[a.Name]?.length })
    types.unshift({
        Name: "All", Tags: {
            "DisplayName": {
                "Name": "DisplayName",
                "Value": "All",
                "Private": false,
                "Required": true,
                "Editable": false
            }
        }
    });
   

    return (<>
        {showAlert ? (
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            ><button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={() => { setShowAlert(false) }}>X</button>
                <span class="text-sm">{alertText} </span>
            </div>
        ) : (
            <></>
        )}
        <DeleteModal confirmationText="Are you certain you wish to delete this secured content?" deleteLabel="Delete" onDeleteFn={deleteSC} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
        {showShareModal && <ShareModal content={selectedContent} address={selectedAddress} onClose={() => { LoadList(); GetAddressses(); setShowShareModal(false); }} title={selectedContentTitle} />}
        <ContentList header={"Secured Content"} types={types} groupedRecords={groupedRecords} totalCount={securedcontents.length + Object.keys(individual?.addresses || {}).length} onDelete={handleDeleteClick} onShare={handleShareClick} onView={handleViewClick} onCreateNew={handleCreateNew} spDetails={spDetails} benDetails={benDetails} /></>
    )
}