import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineProfile } from 'react-icons/ai';
import { renderField } from "../utilities/functions";
import { getInfo, UpdateSecuredContent, CreateSecuredContent, GetSecuredContent, GetTemplatesByServiceProviderID, GetTemplateByID } from '../utilities/calls';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tags from './Tags';
import CopyToClipboard from './CopyToClipboard';
import { ProcessTemplate } from "../utilities/templates";
import dayjs from "dayjs";

export default function AddSecureContent() {
  let { state } = useLocation();
  const contenttype = state?.contenttype || "";
  const [content, setContent] = useState({});
  const [sent, setSent] = useState(true);
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [customTags, setCustomTags] = useState([])
  const { id } = useParams(); 
  const [template, setTemplate] = useState(state?.template || {});
  const [fields, setFields] = useState([]);
  const previousPage = state?.previousPage;
  const searchedText = state?.searchedText;
  const readonly = state?.readonly || false;
  const getTemplates = useCallback(() => {
    if(!state?.template){
    let defaultSP = getInfo('DefaultServiceProvider');
    GetTemplatesByServiceProviderID(defaultSP).then((templateIdsArr) => {
        templateIdsArr.forEach((id) => {
            GetTemplateByID(id).then((t) => {  if(t.Name === contenttype) {
            setTemplate(t);
            let allfields = new Map();
            let templatedetails = ProcessTemplate(t);
            allfields.set(templatedetails.contentNameTag, {id: templatedetails.contentNameTag ,label: templatedetails.contentNameTag, type: "alphanumeric"});
            if(templatedetails.tags) Object.values(templatedetails.tags).forEach(tag => allfields.set(tag.Name, {id: tag.Name ,label: tag.Name, type: "alphanumeric", placeholdertext: tag.Value}))
            setFields(fields => [...fields,...allfields.values()])
            fetch(t.URL).then(jsn => jsn.json()).then(data => {setFields(fields => [...fields, ...data])}).catch(err => console.log("err fetching template", err))
            }})
        })
    });
  } else {
    let allfields = new Map();
    let templatedetails = ProcessTemplate(template);
    allfields.set(templatedetails.contentNameTag, {id: templatedetails.contentNameTag ,label: templatedetails.contentNameTag, type: "alphanumeric"});
    if(templatedetails.tags) Object.values(templatedetails.tags).forEach(tag => allfields.set(tag.Name, {id: tag.Name ,label: tag.Name, type: "alphanumeric", placeholdertext: tag.Value}))
    setFields(fields => [...fields,...allfields.values()])
    if(template.URL)
      fetch(template.URL).then(jsn => jsn.json()).then(data => {setFields(fields => [...fields,...data])}).catch(err => console.log("err fetching template", err))
  }
},[contenttype, state?.template, template]);


  useEffect(() => {
    getTemplates();
    
  },[getTemplates])

  useEffect(()=> {
    let templatedetails = ProcessTemplate(template);
    var con = {};

    if(state?.content ) {
      var resp = state.content;
      if(resp && resp.tags)
        Object.values(resp.tags).forEach((t) => {
          con[t.Name] = t.Value;
        })
      con.created = resp.created;
      con.updated = resp.updated;
      setContent(con);
      var keys = fields.forEach((key) => { return key.id.toLowerCase() });
        var tags = {}
        Object.keys(resp.tags)?.forEach((tag) => {
          var name = resp.tags[tag].Name;
          if (name !==  templatedetails.contentTypeTag && keys?.indexOf(name.toLowerCase()) === -1 )
            tags[name] = resp.tags[tag];
        })
        setCustomTags(tags);
      return;
    }
    if(id !== undefined) {
      GetSecuredContent(id).then((resp) => {
        if(resp && resp.tags)
        Object.values(resp.tags).forEach((t) => {
          con[t.Name] = t.Value;
        })
        con.created= resp.created;
        con.updated = resp.updated;
        setContent(con);
        var keys = fields.map((key) => { return key.id.toLowerCase() });
        var tags = {}
        Object.keys(resp.tags).forEach((tag) => {
          var name = resp.tags[tag].Name;

          if (name !== templatedetails.contentTypeTag && keys.indexOf(name.toLowerCase()) === -1 )
            tags[name] = resp.tags[tag];
        })
        setCustomTags(tags);
      }).catch((err) => {console.log('Error getting content', id)})
    }
  }, [fields, id, state.content, template]);


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setContent((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setSent(false);
  };
  function handleAccordionClick(ev) {
    let dv, icon;
    if (ev.target.dataset && ev.target.dataset.accordionTarget) {
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if (dv.classList.contains("hidden")) {
      dv.classList.add("block");
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }
  const addCustomTag = (tag, key) => {
    var tagname = key ? key : tag.Name;
    setCustomTags((prevValues) => ({
      ...prevValues, [tagname]: tag
    }))
    setSent(false);
  }

  const removeCustomTag = (tagName) => {
    var newMap = {...customTags};
    delete newMap[tagName]
    setCustomTags({...newMap} );
    setSent(false);
  }

  const getMergedData = () => {
    let templatedetails = ProcessTemplate(template);
    let ctField = templatedetails.contentTypeTag
    var mergedData = {
      tags: {}
    }
    mergedData.tags[ctField] = {
          Name: ctField,
          Value: contenttype,
          Private: false,
          Required: false,
          Editable: false
        }
    Object.keys(content).forEach(function (key) { mergedData.tags[key] = { Name: key, Value: content[key], Private: false, Required: false, Editable: false } })
    return mergedData;
  }


  const saveSP = (mergedData) => {
    const individualid = getInfo("IndividualID");
    mergedData.individualid = individualid;
    mergedData.id = id;
    UpdateSecuredContent(id, mergedData)
      .then((response) => {
        setShowAlert(true);
        setAlertText("saved successfully!");
        setSent(true);
      })
      .catch((error) => {
        console.error("Error UPDATING service provider details:", error);
        setShowAlert(true);
        setAlertText("Save Service Provider profile failed! Sorry!");
      });
  }

  const addServiceProviderAndSaveSP = (mergedData) => {
    const individualid = getInfo("IndividualID");
    mergedData.individualid = individualid;

    CreateSecuredContent(mergedData).then((r) => {
      setShowAlert(true);
      setAlertText("Data added!");
      setSent(true);
    })
      .catch((error) => {
        console.error("Error UPDATING service provider details:", error);
        setShowAlert(true);
        setAlertText("Save Service Provider profile failed! Sorry!");
      });

  }


  const processForm = () => {
    var mergedData = getMergedData();
    mergedData.tags = { ...mergedData.tags, ...customTags }

    if (id)
      saveSP(mergedData);
    else {
      addServiceProviderAndSaveSP(mergedData)
    }
  }

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label">
            <AiOutlineProfile className="inline-icon" />
            {id ? "Update ": "Add "  } {template?.Tags?.DisplayName?.Value || template?.Name}
          </h1>
          {id ? <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-between"><span className="text-xs md:font-normal text-gray-200" id="scid">{id}<CopyToClipboard elId="scid" /></span><span className="md:items-end md:text-right text-xs italic">{"Last update on " + dayjs(content.updated).format("MMM D YYYY") + " created on " + dayjs(content.created).format("MMM D YYYY")}</span></div> : <></>}

        </div>
        <div id="accordion-collapse" data-accordion="collapse" className="p-5">
          <h2 id="accordion-collapse-heading-1">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
              <span>{template?.Tags?.DisplayName?.Value || template?.Name} Details </span>
              <svg data-accordion-icon class="w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
            <div class="p-5 border border-grey-700">
            <fieldset disabled={readonly ? "disabled" : ""}>
            {fields?.map((element) => {
                  return renderField(element.label, element.id, element.type, content[element.id], handleInputChange, element.options, "", false , element.placeholdertext);
                })
                }
              </fieldset>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-3">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
              <span>More Details </span>
              <svg data-accordion-icon class="w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-3" aria-labelledby="accordion-collapse-heading-3">
            <div class="p-5 border border-grey-700">
            <fieldset disabled={readonly ? "disabled" : ""}>
                <div className="py-5"><Tags isReadOnly={readonly} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>
              </fieldset>
            </div>

          </div>


        </div>
        <section className="flex flex-start gap-2 py-2">
          { readonly ? <></> :
          <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
            {id ? "UPDATE" : "ADD"}
          </button>}

          {!sent ? (
            <Link className="  button-style" to={previousPage ? previousPage : "/securedContents"} state={{ request: state?.arequest, "contenttype": contenttype, text: searchedText }} >Cancel</Link>
          ) : (
            <Link className="  button-style" to={previousPage ? previousPage :"/securedContents"} state={{ request: state?.arequest, "contenttype": contenttype, text: searchedText }}>Close</Link>
          )}
          <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }}>
          <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
              </div>
    </div>
  )
}

