import React, { useEffect, useState } from 'react';
import { AiOutlineProfile } from 'react-icons/ai';
import { renderField } from "../utilities/functions";
import { ProcessTemplate, FirstMatchedTemplate } from '../utilities/templates';
import { getInfo, UpdateSecuredContent,  GetRequestDetails, GetSharedSecuredContent, GetSecondaryTokenForRequest, GetSharesWith, GetGlobalTemplatesList, GetTemplateByID } from '../utilities/calls';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tags from './Tags';
export default function ViewSecuredContentForRequest() {
    const [contenttype, setContenttype] = useState("");
    const [content, setContent] = useState({});
    const [sent, setSent] = useState(true);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [customTags, setCustomTags] = useState([])
    const { requestid } = useParams();
    const [id, setId] =useState();
    const [fields, setFields] = useState([]);
    const { state: { previousPage, tokens }} = useLocation();
    const [bestTemplate, setBestTemplate] = useState({});


    useEffect(() => {
        if (requestid !== undefined) {
            var con = {};
            GetRequestDetails(requestid)
                .then((request) => {
                    var ben = request.fromBeneficiaryID !== "" ? request.fromBeneficiaryID : (request.fromServiceProviderID !== "" ? request.fromServiceProviderID : "");
                    if(request.fromBeneficiaryID !== "" || request.fromServiceProviderID === ""){     
                    GetSecondaryTokenForRequest(requestid).then((response) => {
                        GetSharesWith(getInfo("IndividualID")).then(records => {
                            const record = records.find(record => record.token === response.token);
                            GetSharedSecuredContent(record.securedcontentID,  record.token, record.beneficiaryID ,record.individualID).then((resp) => {
                            
                            setId(resp.id);
                            var ct = resp.tags?.contenttype?.Value || "";
                            setContenttype(ct);
                            const matchGlobalList = (spid, newtemplates) => {
                                newtemplates.forEach((id) => {
                                    GetTemplateByID(id).then((gt) => {
                                        if(gt) {
                                        let newmatch = FirstMatchedTemplate(resp, [gt]);
                                        if (newmatch) {
                                            let temp = ProcessTemplate(newmatch);
                                            setBestTemplate(temp)
                                            let allfields = new Map();
                                            allfields.set(temp?.contentNameTag, { id: temp?.contentNameTag, label: temp?.contentNameTag, type: "alphanumeric" });
                                            if (temp?.tags) Object.values(temp.tags).forEach(tag => allfields.set(tag.Name, { id: tag.Name, label: tag.Name, type: "alphanumeric", placeholdertext: tag.Value }))
                                            setFields(fields => [...fields, ...allfields.values()])
                                            var con = {};
                                            if (resp && resp.tags)
                                                Object.values(resp.tags).forEach((t) => {
                                                    con[t.Name] = t.Value;
                                                })
                                            setContent(con);
                                            var keys = [...allfields.values()].map((key) => { return key.id.toLowerCase() });
                                            var tags = {}
                                            if (resp && resp.tags && temp) {
                                                Object.keys(resp?.tags)?.forEach((tag) => {
                                                    var name = resp?.tags[tag].Name;
                                                    if (name !== temp?.contentTypeTag && keys.indexOf(name.toLowerCase()) === -1)
                                                      tags[name] = resp.tags[tag];
                                                })
                                            }
                                            setCustomTags(tags);
                                            return;
                                        }
                                    }
                                    }
                                    ).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
                                })
                            }
                            GetGlobalTemplatesList("", matchGlobalList);
                           
                        }).catch((err) => { console.log('Error getting content', err) })

                        }).catch(err => console.log('Error getting records for secure content id', err))

                       
                    }
                    ).catch(err => console.log('Error getting token', err));
                }else {
                    //sp so check record
                    GetSharedSecuredContent(request.securedcontentID, tokens[request.securedcontentID], ben, request.toIndividualID).then((resp) =>{
                        if (resp && resp.tags)
                            Object.values(resp.tags).forEach((t) => {
                                con[t.Name] = t.Value;
                            })
                        setContent(con);
                        setId(resp.id);
                        var ct = resp.tags?.contenttype?.Value || "";
                        setContenttype(ct);
                        const matchGlobalList = (spid, newtemplates) => {
                            newtemplates.forEach((id) => {
                                GetTemplateByID(id).then((gt) => {
                                    if(gt) {
                                    let newmatch = FirstMatchedTemplate(resp, [gt]);
                                    if (newmatch) {
                                        let temp = ProcessTemplate(newmatch);
                                        setBestTemplate(temp)
                                        let allfields = new Map();
                                        allfields.set(temp?.contentNameTag, { id: temp?.contentNameTag, label: temp?.contentNameTag, type: "alphanumeric" });
                                        if (temp?.tags) Object.values(temp.tags).forEach(tag => allfields.set(tag.Name, { id: tag.Name, label: tag.Name, type: "alphanumeric", placeholdertext: tag.Value }))
                                        setFields(fields => [...fields, ...allfields.values()])
                                        var con = {};
                                        if (resp && resp.tags)
                                            Object.values(resp.tags).forEach((t) => {
                                                con[t.Name] = t.Value;
                                            })
                                        setContent(con);
                                        var keys = [...allfields.values()].map((key) => { return key.id.toLowerCase() });
                                        var tags = {}
                                        if (resp && resp.tags && temp) {
                                            Object.keys(resp?.tags)?.forEach((tag) => {
                                                var name = resp?.tags[tag].Name;
                                                if (name !== temp?.contentTypeTag && keys.indexOf(name.toLowerCase()) === -1)
                                                  tags[name] = resp.tags[tag];
                                            })
                                        }
                                        setCustomTags(tags);
                                        return;
                                    }
                                }
                                }
                                ).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
                            })
                        }
                        GetGlobalTemplatesList("", matchGlobalList);
                    }) 
                }
            } 
                ).catch(err => console.log('error fetching request details', err));
        }
    }, [requestid, tokens])

   
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        setContent((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        setSent(false);
    };
    function handleAccordionClick(ev) {
        let dv, icon;
        if (ev.target.dataset && ev.target.dataset.accordionTarget) {
            dv = document.getElementById(ev.target.dataset.accordionTarget);
            icon = ev.target.querySelector("svg");
        } else {
            const button = ev.target.closest("button");
            dv = document.getElementById(button.dataset.accordionTarget);
            icon = button.querySelector("svg");
        }
        if (dv.classList.contains("hidden")) {
            dv.classList.add("block");
            dv.classList.remove("hidden");
            icon.classList.remove("rotate-180");
        } else {
            dv.classList.add("hidden");
            dv.classList.remove("block");
            icon.classList.add("rotate-180");
        }
    }
    const addCustomTag = (tag, key) => {
        var tagname = key ? key : tag.Name;
        setCustomTags((prevValues) => ({
            ...prevValues, [tagname]: tag
        }))
        setSent(false);
    }

    const removeCustomTag = (tagName) => {
        var newMap = {...customTags};
        delete newMap[tagName]
        setCustomTags({...newMap} );
        setSent(false);
    }

    const getMergedData = () => {
        var mergedData = {
            tags: {
                contenttype: {
                    Name: "contenttype",
                    Value: contenttype,
                    Private: false,
                    Required: false,
                    Editable: false
                }
            }
        }
        Object.keys(content).forEach(function (key) { mergedData.tags[key] = { Name: key, Value: content[key], Private: false, Required: false, Editable: false } })
        return mergedData;
    }


    const saveSP = (mergedData) => {
        const individualid = getInfo("IndividualID");
        mergedData.individualid = individualid;
        mergedData.id = id;
        UpdateSecuredContent(id, mergedData)
            .then((response) => {
                setShowAlert(true);
                setAlertText("saved successfully!");
                setSent(true);
            })
            .catch((error) => {
                console.error("Error UPDATING service provider details:", error);
                setShowAlert(true);
                setAlertText("Save Service Provider profile failed! Sorry!");
            });
    }

    const processForm = () => {
        var mergedData = getMergedData();
        mergedData.tags = { ...mergedData.tags, ...customTags }
         saveSP(mergedData);   
    }

    return (
        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <div className="mx-auto max-w-screen-lg form-container p-5">
                <div>
                    <h1 className="form-label">
                        <AiOutlineProfile className="inline-icon" />
                        {bestTemplate?.templateDisplayName}
                    </h1>
                </div>
                <div id="accordion-collapse" data-accordion="collapse" className="p-5">
                    <h2 id="accordion-collapse-heading-1">
                        <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                            <span>{bestTemplate?.templateDisplayName} Details </span>
                            <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                            </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                        <div class="p-5 border border-grey-700">
                        <fieldset disabled="disabled">
                                {fields?.map((element) => {
                                return renderField(element.label, element.id, element.type, content[element.id], handleInputChange, element.options, "", false, element.placeholdertext);
                            })
                            }
                            </fieldset>
                        </div>
                    </div>
                    <h2 id="accordion-collapse-heading-3">
                        <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
                            <span>More Details </span>
                            <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                            </svg>
                        </button>
                    </h2>
                    <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
                        <div class="p-5 border border-grey-700">
                            <fieldset disabled="disabled">

                                <div className="py-5"><Tags isReadOnly={true} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>

                            </fieldset>
                        </div>

                    </div>


                </div>
                <section className="flex flex-start gap-2 py-2">
                    <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="button-style positive-action">
                        {id ? "UPDATE" : "ADD"}
                    </button>

                    {!sent ? (
                        <Link className="  button-style" to={previousPage ? previousPage : "/"} state={{ "contenttype": contenttype }} >Cancel</Link>
                    ) : (
                        <Link className="  button-style" to={previousPage ? previousPage : "/"} state={{ "contenttype": contenttype }}>Close</Link>
                    )}
                    <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }}>
                    <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
                    </div>
                </section>
            </div>
        </div>
    )
}

