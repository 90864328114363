import { BsClipboard2 } from "react-icons/bs";
import { BsClipboard2Check } from "react-icons/bs";
import { useEffect, useState } from "react";
import { copyToClipboard } from "../utilities/functions";


export default function CopyToClipboard({elId}) {
    const [copied, setCopied] = useState();
    let timer = 0;

    useEffect(() => { setCopied(false); return  () => clearInterval(timer);}, [timer]);

    const copy = () => {
       
        const text =  document.getElementById(elId)?.innerHTML || '';
        copyToClipboard(text);
        setCopied(true)
        timer = setTimeout(() => {
            setCopied(false);
          }, 4000);
    }

    return <button onClick={copy} type="submit" className="pl-1 hover:bg-555"> { !copied ? <BsClipboard2 className="inline-icon" style={{width: "1em", height: "1em"}} title="Copy to clipboard" /> : <BsClipboard2Check fill="green" className="inline-icon" title="Copied to clipboard"/>}</button> 
    
}