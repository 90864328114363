import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GetTemplateByID, GetSecuredContent, GetSecuredContents, UpdateRequest, GetStreams, GetStream, GetGlobalTemplatesList } from "../utilities/calls";
import { AiOutlineImport, AiOutlineProfile } from "react-icons/ai";
import { ProcessTemplate } from "../utilities/templates";

const ProcessRequest = () => {
  let location = useLocation();
  const Arequest = location?.state?.request || {};
  const addressList = useMemo(() => location?.state?.addresses || [], [location?.state?.addresses]);
  const previousPage = location?.state?.previousPage;
  const requestFrom = location?.state?.requestFrom ? Object.values(location.state.requestFrom).map((e) => { return e ? e : "" }) : Arequest?.fromIndividualEmail || "";
  const [addressSelected, SelectAddress] = useState(
    addressList[Object.keys(addressList)[0]]
  );
  const [selectedSecuredContent, setSelectedSecuredContent] = useState({});
  const [saved, setSave] = useState();
  const [securedcontents, setSecuredcontents] = useState([]);
  const [bestTemplate, setBestTemplate] = useState();
  const [selectedTemplateSC, setSelectedTemplateSC] = useState([]);
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [streams, setStreams] = useState([]);
  const [streamDetails, setStreamDetails] = useState({});
  const [selectedStream, setSelectedStream] = useState();
  const processForm = () => {
    let updatedrequest = Object.create(Arequest);

    if (Arequest?.tags?.contenttype?.Value === "Addresses" || Arequest.requestType === "SHARE_ADDRESS") {
      if (addressSelected) {
        updatedrequest.addressID = addressSelected.id;
      } else {
        console.log("Address not selected trying first address");
        updatedrequest.addressID = Object.keys(addressList)[0];
      }
    }
    if (Arequest.requestType !== "SHARE_ADDRESS" && Arequest.requestType !== "SHARE_STREAM") {
      updatedrequest.securedcontentID = document.getElementById('contentselect').value;
      if (!Arequest.tags.contenttype) {
        Arequest.tags.contenttype = {};
        Arequest.tags.contenttype.Value = "notes";
        Arequest.tags.contenttype.Name = "contenttype";
      }
    }
    if (Arequest.requestType === "SHARE_STREAM") {
      updatedrequest.streamID = document.getElementById('contentselect').value;
      if (!Arequest.tags.contenttype) {
        Arequest.tags.contenttype = {};
        Arequest.tags.contenttype.Value = "Documents";
        Arequest.tags.contenttype.Name = "contenttype";
      }
    }
    if (updatedrequest.addressID !== "" || updatedrequest.securedcontentID !== "" || updatedrequest.streamID !== "") {
      updatedrequest.status = "ACCEPTED";
      updatedrequest.requestType = Arequest.requestType;
      updatedrequest.id = Arequest.id;
      updatedrequest.tags = Arequest.tags;
      UpdateRequest(updatedrequest)
        .then((response) => {
          setSave(true);
          //window.alert("Request Update Success!");
          setShowAlert(true);
          setAlertText("Request Update Success!");
        })
        .catch((error) => {
          //window.alert("Request Update Failed!");
          setShowAlert(true);
          setAlertText("Request Update Failed!");
          console.error(
            `Error updaing details for ID ${updatedrequest.id}:`,
            error
          );
        });
    } else {
      //window.alert("No address to select");
      setShowAlert(true);
      setAlertText("No address to select.");
    }
  };

  useEffect(()=>{
    if (bestTemplate) {
      const ct = bestTemplate.contentTypeTag;
      let filtered = securedcontents.filter((sc) => {
        if(sc && sc.tags){
          let ctTag = sc.tags[ct];
          return ctTag?.Value === Arequest?.tags?.contenttype?.Value;
        } else {
          return false
        }
      });
      setSelectedTemplateSC([...filtered]);
  }
  },[Arequest?.tags?.contenttype?.Value, bestTemplate, securedcontents]);

  useEffect(() => {
    if (Arequest.requestType !== "SHARE_ADDRESS" && Arequest.requestType !== "SHARE_STREAM") {
      GetSecuredContents().then(resp => {
        setSecuredcontents(resp);
      }).catch(err => console.log('error fetching secured content', err))
      if (Arequest?.securedcontentID !== "") {
        GetSecuredContent(Arequest.securedcontentID).then((resp) => {
          setSelectedSecuredContent(resp);
        }).catch(err => console.log("Error fetching secured content", err))
      }
    } else if (Arequest.requestType === "SHARE_STREAM") {
      GetStreams().then(resp => {
        setStreams(resp)
      }).catch(err => console.log('Error fetching documents', err))
    }
  }, [Arequest.requestType, Arequest.securedcontentID, Arequest.streamID])

  useEffect(() => {
    streams.forEach(stream => {
      if (!streamDetails[stream.ID]) {
        GetStream(stream.ID).then(resp => {
          if (Arequest.streamID && Arequest.streamID === stream.ID) {
            setSelectedStream(resp);
          }
          setStreamDetails((streamDetails) => ({ ...streamDetails, [stream.ID]: resp }))
        }).catch(err => console.log('Error getting stream details', err))
      }

    })
  }, [Arequest.streamID, streamDetails, streams]);
  useEffect(() => {
    if (Arequest.requestType === "SHARE_SECUREDCONTENT") {
      const requestContentType = Arequest?.tags?.contenttype?.Value || "notes";
      const matcher = (spid, newtemplates) => {
        newtemplates.forEach((id) => {
          GetTemplateByID(id).then((gt) => {
            const templatedetails = ProcessTemplate(gt);
            if (requestContentType === templatedetails.templateName) {
              setBestTemplate(templatedetails);
            }
          }
          ).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
        });

      }
      GetGlobalTemplatesList("", matcher);        //get templates? do we need it?
    }
  }, [Arequest.fromServiceProviderID, Arequest.requestType, Arequest?.tags?.contenttype?.Value, securedcontents]);



  const handleInputChange = (e) => {
    const { value } = e.target;
    SelectAddress(addressList[value]);
    Arequest.addressID = value;
    setSave(false);
  };

  useEffect(() => {
    console.log(selectedTemplateSC.length)
    if (Arequest.requestType === "SHARE_ADDRESS") {
      setSave(Object.keys(addressList).length === 0);
    } else if (Arequest.requestType === "SHARE_STREAM") {
      setSave(streams.length === 0)
    } else {
      setSave(selectedTemplateSC.length === 0)
    }
  }, [Arequest.requestType, addressList, selectedTemplateSC.length, streams.length])

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label py-2">
            <AiOutlineImport className="inline-icon" />
            Request Details
          </h1>
        </div>

        <div className="grid grid-cols-1">
          <p
            style={{ marginBottom: "20px" }}
            className="form-label"
          >
            You have a request to{" "}
            {Arequest?.requestType === "SHARE_ADDRESS" ? "Share address" : Arequest?.requestType === "SHARE_STREAM" ? "Share document" : "Share content"}
          </p>
          <form className="" id="address select">
            <label className="form-label" htmlFor="addressselect">
              Choose {Arequest?.requestType === "SHARE_ADDRESS" ? " address" : Arequest?.requestType === "SHARE_STREAM" ? " document" : bestTemplate?.templateName}
            </label>
            {Arequest?.requestType === "SHARE_ADDRESS" ?
              Object.keys(addressList).length === 0 ?
                <><p className="py-3">`You don't have any address(es) yet.`
                  <Link className="" to={{ pathname: "/AddAddressDetails" }} state={{ request: Arequest, addresses: addressList, previousPage: "/process-request" }} >
                    <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                      <AiOutlineProfile className="inline-icon" /> Add New Address
                    </button>
                  </Link>  </p></>
                :
                <select defaultValue={`${Arequest?.addressID || addressSelected?.id || Object.keys(addressList)[0]}`} onChange={handleInputChange} className="form-input" name="addressselect" id="addressselect">
                  {
                    Object.keys(addressList || {})
                      .map((addressId, index) => {
                        const address = addressList[addressId];
                        return (<option key={`${address.id}`} value={`${address.id}`}>{`${address.tags?.atag?.Name}`}</option>);
                      }
                      )
                  }
                </select>

              : Arequest.requestType === "SHARE_STREAM" ?
                streams?.length > 0 ?
                  <select id="contentselect" onChange={handleInputChange} className="form-input" >
                    {
                      Object.values(streamDetails).sort((a, b) => {
                        return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase());
                      })
                        ?.map((stream, index) => {
                          return (
                            <option key={stream.ID} value={stream.ID} selected={selectedStream?.ID === stream.id || index === 0}>{stream.tags?.Name?.Value}</option>
                          );
                        })
                    }
                  </select>
                  :
                  <><p className="py-3">You don't have any Document(s) yet.
                    <Link className="" to={{ pathname: "/documentdetails/" }} state={{ arequest: Arequest, previousPage: "/process-request" }} >
                      <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Document">
                        <AiOutlineProfile className="inline-icon" /> Add New Document
                      </button>
                    </Link>  </p></>

                :
                securedcontents?.length > 0 && selectedTemplateSC?.length > 0 ?
                  <select id="contentselect" onChange={handleInputChange} className="form-input" >
                    {
                      selectedTemplateSC.sort((a, b) => {
                        return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase());
                      })
                        ?.map((sc, index) => {
                          return (
                            <option key={sc.id} value={sc.id} selected={selectedSecuredContent.id === sc.id || index === 0}>{sc.tags?.[bestTemplate ? ProcessTemplate(bestTemplate)?.contentNameTag : "name"]?.Value || sc.tags?.name?.Value || sc.tags?.title?.Value || sc.tags?.Name?.Value || sc.tags?.Title?.Value || sc.id}</option>
                          );
                        })
                    }
                  </select>
                  :
                  <><p className="py-3">You don't have any Secured content(s) yet.
                    <Link className="" to={{ pathname: "/addsecurecontent/" }} state={{ arequest: Arequest, contenttype: Arequest?.tags?.contenttype?.Value ? Arequest?.tags?.contenttype?.Value : bestTemplate ? ProcessTemplate(bestTemplate)?.templateName : "notes", template: bestTemplate, previousPage: "/process-request" }} >
                      <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Secured Content">
                        <AiOutlineProfile className="inline-icon" /> Add New Secure Content
                      </button>
                    </Link>  </p></>
            }
          </form>
          <form id="form-address-new" name="form-address-new">
            <label htmlFor="fromemail" className="form-label" >
              Request from
            </label>
            <p id="fromemail" name="fromemail" className=" px-2 py-2 form-input">
              {requestFrom}
            </p>
            <label className="form-label" htmlFor="email" >
              It was sent to Email
            </label>
            <p id="email" name="email" className="px-2 py-2 form-input">
              {" "}
              {Arequest?.toIndividualEmail || ""}{" "}
            </p>

          </form>
          <section class="flex justify-start items-center space-x-4 py-2 ">
            {saved ? <></> : <button formMethod="dialog" formTarget="top" type="submit" onClick={processForm} form="address select" id="btn-update-request-submit" className="button-style positive-action">
              SAVE
            </button>}
            <div>
              {!saved ? (
                <Link to={previousPage} >
                  <button className="button-style" >Cancel</button>
                </Link>
              ) : (
                <Link to={previousPage} >
                  <button className="button-style">Close</button>
                </Link>
              )}
            </div>

            <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }}>
            <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
            </div>
          </section>

        </div>
      </div>
    </div>
  );
};

export default ProcessRequest;
