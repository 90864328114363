import React, { useEffect, useLayoutEffect, useState } from "react";
import { GetAddress, GetAccount, GetAccounts, GetSharesWithServiceProvider, GetServiceProvider, getInfo, GetBeneficiary, GetSharedSecuredContent, GetSharesWith, GetTemplatesByServiceProviderID, GetTemplateByID, GetRequestDetails, GetSharedStream } from "../utilities/calls";
import Login from "./Login";
import ContentBox from "./SecuredContentBox";
import AddressBox from "./AddressBox";
import { ProcessTemplate, FirstMatchedTemplate } from "../utilities/templates";
import StreamBox from "./StreamBox";
 
const ServiceProviderShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [shares, setShares] = useState({});
    const [sharesBen, setSharesBen] = useState({});
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList] = useState({});
    const [beneficiaryList, setBeneficiaryList] = useState([]);
    const [securedContents, setSecuredContents] = useState([]);
    const [streams, setStreams] = useState([]);
    const [state, setState] = useState(0);
    const [refBenAcceptedSpIds, setRefBenAcceptedSpIds] = useState([]);
    let spIds = [];
    const [contenttype, setContenttype] = useState("");

    const [templates, setTemplates] = useState([{Name: "Documents", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Documents",
        "Private": false,
        "Required": true,
        "Editable": false
    }}},{Name: "Addresses", Tags: { "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
    }}}]);

    useLayoutEffect(() => {
        setUserId(getInfo("UserID"));
        let defaultSP = getInfo('DefaultServiceProvider');
        if(defaultSP) {
            spIds.push(defaultSP)
            getTemplates(defaultSP);
        }
    }, []);

    const handleTypeFilter = (e) => {
        const val = e.target.value;
        setContenttype(val);
    }

    const getTemplates = (spid) => {
        GetTemplatesByServiceProviderID(spid).then((templateIdsArr) => {
            templateIdsArr.forEach((id) => {
                GetTemplateByID(id).then((template) => {
                    setTemplates((templates) =>   [...templates, template]);
                }).catch(err => console.log('Error fetching templates for spid ', spid, err));
            })
        }).catch(err => console.log('Error fetching templates for spid ', spid, err));
    }

    useLayoutEffect(() => {

        GetAccounts().then((response) => {
            if (Object.keys(response).length === 0) if (state !== 2) setState(1);
            Object.keys(response).forEach((key, index) => {
                GetAccount(key).then((accDetails) => {
                    if (Object.keys(accDetails.ServiceProviders).length === 0 && Object.keys(accDetails.Beneficiaries).length === 0) if (state !== 2) setState(1);
                    Object.keys(accDetails.Beneficiaries).forEach((k, i) => {
                       
                        GetBeneficiary(k).then((benDetails) => {
                            if (state !== 2) setState(1);
                            (
                                setBeneficiaryList((benList) => ([...benList, benDetails])))
                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })

                        GetSharesWith(k).then((share) => {
                            if (share.length > 0) {
                               
                                setSharesBen((sharesBen) => ({ ...sharesBen, [share[0].beneficiaryID]: share }));
                                Promise.all(
                                    share.filter(d => d.securedcontentID === "" && d.streamID === "").map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetAddress(el.addressID, benid, el.token, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;

                                        } catch (err) {
                                            console.error("Error fetching addresses:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((addrs) => {
                                    setAddresses((addresses) => [...addresses, ...addrs]);
                                    if (state !== 2) setState(1);
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });
                                Promise.all(
                                    share.filter(d => d.securedcontentID !== "" && d.streamID === "").map(async (el) => {
                                        try {
                                            if (spIds.findIndex((v) => v === el.serviceProviderID) === -1) {
                                                spIds.push(el.serviceProviderID)
                                                getTemplates(el.serviceProviderID);
                                            }
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetSharedSecuredContent(el.securedcontentID, el.token, benid, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;
                                        } catch (err) {
                                            console.error("Error fetching secured content:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((content) => {
                                    setSecuredContents((securedContents) => [...securedContents, ...content]);
                                    if (state !== 2) setState(1)
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });
                                Promise.all(
                                    share.filter(d => d.securedcontentID === "" && d.streamID !== "" ).map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetSharedStream(el.streamID, el.token, benid, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;
                                        } catch (err) {
                                            console.error("Error fetching secured content:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((streams) => {
                                    setStreams((prev) => [...prev, ...streams]);
                                    if (state !== 2) setState(1)
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });

                            }
                        }).catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })
                    });


                    Object.keys(accDetails.ServiceProviders).forEach((k, i) => {
                        if (spIds.findIndex((v) => v ===k) === -1) {
                            spIds.push(k)
                            getTemplates(k);
                        }
                            GetServiceProvider(k).then((spDetails) => {
                                if (state !== 2) setState(1);
                                setServiceProviderList((serviceProviderList) => ({ ...serviceProviderList, [spDetails.id]: spDetails }));
                            }).catch(e => {
                                console.log(e);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                                setState(2);
                            })
                            GetSharesWithServiceProvider(k).then((share) => {
                                if (share.length > 0) {
                                
                                    let bens = share.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                                    bens.forEach((b,i) => {
                                        if(b.tags?.RequestId?.Value){
                                            GetRequestDetails(b.tags?.RequestId?.Value).then(c =>{
                                                let benRef = c.tags?.beneficiaryReference?.Value;
                                                if(benRef && benRef[0] === b.beneficiaryID) {
                                                    setRefBenAcceptedSpIds((refBenAcceptedSpIds) => ([...refBenAcceptedSpIds, b.serviceProviderID + b.beneficiaryID]));
                                                }
                                            })
                                        }
                                        GetBeneficiary(b.beneficiaryID).then((benDetails) => {
                                            if (state !== 2) setState(1);
                                            setBeneficiaryList((benList) => ([...benList, benDetails]))
                                        }).catch(e => {
                                            console.log(e);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                            setState(2);
                                        })
                                    })
                                    const sharewithben0 = share.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") === 0);
                                    let filteredShares = sharewithben0.filter((val) => bens.findIndex(v => v.serviceProviderID === val.serviceProviderID && v.addressID === val.addressID && v.streamID === val.streamID && v.securedcontentID === val.securedcontentID) === -1  )
                                    filteredShares = [...filteredShares, ...bens];
                                    setShares((shares) => ({ ...shares, [share[0].serviceProviderID]: filteredShares }));
                                    Promise.all(
                                        //while fetching data we should only consider the records with primary token. 
                                        share.filter(d => d.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 && d.securedcontentID === "" && d.streamID === "").map(async (el) => {
                                            try {
                                                const result = await GetAddress(el.addressID, el.serviceProviderID, el.token, el.individualID);
                                                result.userEmail = decodeURIComponent(
                                                    el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                                );
                                                result.SharedWithEmail = decodeURIComponent(
                                                    el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                                );

                                                return result;

                                            } catch (err) {
                                                console.error("Error fetching addresses:", err);
                                                setShowAlert(true);
                                                setAlertText("Error fetching data");
                                            }
                                        })
                                    ).then((addrs) => {
                                        setAddresses((addresses) => [...addresses, ...addrs]);
                                        if (state !== 2) setState(1);
                                    }).catch((e) => {
                                        console.log(e);
                                        setShowAlert(true);
                                        setAlertText("Error fetching data");
                                        setState(2);
                                    });
                                    Promise.all(
                                        //while fetching data we should only consider the records with primary token. 
                                        share.filter(d => d.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 && d.securedcontentID !== "" && d.streamID === "").map(async (el) => {
                                            try {
                                                const result = await GetSharedSecuredContent(el.securedcontentID, el.token, el.serviceProviderID, el.individualID);
                                                result.userEmail = decodeURIComponent(
                                                    el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                                );
                                                result.SharedWithEmail = decodeURIComponent(
                                                    el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                                );
                                                return result;
                                            } catch (err) {
                                                console.error("Error fetching secured content:", err);
                                                setShowAlert(true);
                                                setAlertText("Error fetching data");
                                            }
                                        })
                                    ).then((content) => {
                                        setSecuredContents((securedContents) => [...securedContents, ...content]);
                                        if (state !== 2) setState(1);
                                    }).catch((e) => {
                                        console.log(e);
                                        setShowAlert(true);
                                        setAlertText("Error fetching data");
                                        setState(2);
                                    });

                                    Promise.all(
                                        //while fetching data we should only consider the records with primary token. 
                                        share.filter(d => d.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 && d.securedcontentID === "" && d.streamID !== "").map(async (el) => {
                                            try {
                                                const result = await GetSharedStream(el.streamID, el.token, el.serviceProviderID, el.individualID);
                                                result.userEmail = decodeURIComponent(
                                                    el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                                );
                                                result.SharedWithEmail = decodeURIComponent(
                                                    el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                                );
                                                return result;
                                            } catch (err) {
                                                console.error("Error fetching secured content:", err);
                                                setShowAlert(true);
                                                setAlertText("Error fetching data");
                                            }
                                        })
                                    ).then((streams) => {
                                        setStreams((prev) => [...prev, ...streams]);
                                        if (state !== 2) setState(1);
                                    }).catch((e) => {
                                        console.log(e);
                                        setShowAlert(true);
                                        setAlertText("Error fetching data");
                                        setState(2);
                                    });
                                }

                            }).catch(e => {
                                console.log(e);
                                setShowAlert(true);
                                setAlertText("Error fetching data");
                                setState(2);
                            })
                    })
                })
            })
        }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
            setState(2);
        });
    }, []);

    const showSPs = () => {
        if(Object.keys(shares).length > 0 ){
            return Object.keys(shares).map((k) => {
                return <div>
                    <h1 className="p-5 bold-text-input">Shared with {serviceProviderList[k]?.tags?.name?.Value} <span className="text-sm text-gray-200 font-semibold">{" (Service Provider)"}</span></h1>
                    <div class=" bg-gray  ">
                        <div class="p-5 max-w-full">
                        <div className="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">

                                {shares[k].map((s) => {
                                    var bens =  refBenAcceptedSpIds.findIndex(v => v === s.serviceProviderID + s.beneficiaryID) !== -1 ? [s] : [] ;
                                    var from = decodeURIComponent(
                                        s?.tags?.UserEmail ? s.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        s?.tags?.SharedWithEmail ? s.tags.SharedWithEmail.Value : ""
                                    );
                                    var content = {}; 
                                    var address = {};
                                    var stream = {};
                                    if(s.securedcontentID){
                                     content = securedContents.find(ele => ele?.id === s.securedcontentID);
                                     if(content) { 
                                        let matchedtemplate = FirstMatchedTemplate(content, templates);
                                        if(contenttype === "" || contenttype === content?.tags[ProcessTemplate(matchedtemplate)?.contentTypeTag]?.Value) {
                                            return <ContentBox template={matchedtemplate} created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} content={content} from={from} to={to} requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} ben={bens} isBusiness={true}/>;
                                        } else return <></>
                                     } return <></>
                                    } else if(s.streamID){
                                        stream = streams.find(ele => ele?.ID === s.streamID);
                                        if(stream) { 
                                            if(contenttype === "" || contenttype === "Documents") {
                                                return <StreamBox token={s.token} beneficiaryID={s.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? s.serviceProviderID : s.beneficiaryID} individualID={s.individualID} created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} stream={stream} from={from} to={to} requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} ben={bens} isBusiness={true}/>;
                                            } else return <></>
                                         } return <></>
                                    } else {
                                      if(contenttype === "Addresses"  || contenttype === "")  {
                                        address = addresses.find((ele) => s.addressID === ele?.id)
                                        return <AddressBox created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""}  from={from} to={to} address={address} requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} ben={bens} isBusiness={true}></AddressBox>
                                      } else return <></>
                                    }                                               
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            }) 
        } else return <></>
    }

    const showBens = () => {
        if(Object.keys(sharesBen).length > 0 ){
            return Object.keys(sharesBen).map((k) => {
                return <div> 
                    <h1 className="p-5 bold-text-input">Shared with {beneficiaryList.find(b => b.id === k)?.tags?.name?.Value}<span className="text-sm text-gray-200 font-semibold">{ " (Beneficiary)"}</span></h1>
                    <div class=" bg-gray  ">
                        <div class="p-5 max-w-full">
                        <div className="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                                        
                                {sharesBen[k].map((s) => {
                                    var content = {}; 
                                    var address = {};
                                    var stream = {};
                                    if(s.securedcontentID)
                                        content = securedContents.find(ele => ele?.id === s.securedcontentID)
                                    else if(s.streamID) 
                                        stream = streams.find(ele => ele?.ID === s.streamID)
                                    else
                                        address = addresses.find((ele) => s.addressID === ele?.id)
                                    var from = decodeURIComponent(
                                        s?.tags?.UserEmail ? s.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        s?.tags?.SharedWithEmail ? s.tags.SharedWithEmail.Value : ""
                                    );
                                    if(s.securedcontentID){
                                        content = securedContents.find(ele => ele?.id === s.securedcontentID)
                                        if(content) {
                                        let matchedtemplate = FirstMatchedTemplate(content, templates);
                                        if(contenttype === "" || contenttype === content?.tags[ProcessTemplate(matchedtemplate)?.contentTypeTag]?.Value) {
                                            return <ContentBox  template={matchedtemplate} created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} content={content} from={from} to={to} isBusiness={true}/>;
                                        } else return <></>
                                        } else return <></>
                                    } else if(s.streamID) {
                                        if(contenttype === "" || contenttype === "Documents") {
                                            return <StreamBox token={s.token} beneficiaryID={s.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? s.serviceProviderID : s.beneficiaryID} individualID={s.individualID} created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} stream={stream} from={from} to={to} isBusiness={true}/>;
                                        } else return <></>
                                    } else {
                                        if(contenttype === "Addresses"  || contenttype === "")  {
                                         address = addresses.find((ele) => s.addressID === ele?.id)
                                         return <AddressBox created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""}  from={from} to={to} address={address} isBusiness={true}></AddressBox>
                                        } else return <></>
                                       }              
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            })
        } else return <></>
    }


    return userId ? (

        <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
            <h1 className="bold-text-input">Secure Content Shares</h1>
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >                <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
                <span class="text-sm">{alertText} </span>
            </div>
            {
                state === 0 ? "Loading..." : Object.keys(shares).length === 0 && Object.keys(sharesBen).length === 0 ? (<div className="w-full h-full p-2 flex items-start justify-start text-blue-300">
                    <p className="text-start">It looks like you don't have any shared content yet. Keep adding and updating your profile so people can start sharing with you!</p>
                </div>) : <> <div className="p-5"><select id="contenttype" class="p-2 border font-semibold rounded-lgblock bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" onChange={handleTypeFilter}>
                    <option selected value={""}>All Content Types</option>
                    {templates.sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a?.Name)?.toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b?.Name)?.toLowerCase()) }).map(ele => { return <option value={ele?.Name}> {ele?.Tags?.DisplayName?.Value || ele?.Name} </option> })}
                </select></div> {showSPs()} {showBens()}</>

            }
        </div>

    ) : (
        <Login />
    );
};


export default ServiceProviderShares;
