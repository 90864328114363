import { useEffect, useState } from "react"
import { GetAddress, GetGlobalTemplatesList, getInfo, GetSharedSecuredContent, GetSharedStream, GetSharesWith, GetTemplateByID } from "../utilities/calls";
import { useParams, Link } from "react-router-dom";
import { AiOutlineProfile } from "react-icons/ai";
import CopyToClipboard from "./CopyToClipboard";
import { address_locales, countryLocaleList, ProcessTemplate, FirstMatchedTemplate } from "../utilities/templates";
import { renderField } from "../utilities/functions";
import Tags from "./Tags";
import StreamDetails from "./StreamDetails";

export default function VisitLinkContent() {
    const { individualID, type, contentid } = useParams();
    const [updatedaddress, updateAddress] = useState({
        name: "",
        addressee: "",
        street: "",
        city: "",
        zipcode: "",
        state: "",
        phone: "",
        email: "",
        country: "",
    });

    const [address, setAddress] = useState({});
    const [stream, setStream] = useState({});
    const [securedcontent, setSecuredcontent] = useState({});
    const [matchedtemplate, setMatchedTemplate] = useState();
    const [fields, setFields] = useState([])
    const [filteredSC, setFilteredSC] = useState();
    const [token, setToken] = useState();
    const [beneficiaryId, setBeneficiaryId] = useState();
    const [customTags, setCustomTags] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(
        Intl.NumberFormat().resolvedOptions().locale
    );

    const handleInputChange = (e) => {
        if (e.target.name === "country") {
            handleCountryChange(e);
            return;
        }
        const { name, value } = e.target;
        updateAddress((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleCountryChange = (e) => {
        const countryVal = countryLocaleList.find(
            (o) => o.name === e.target.value
        ).value;
        updateAddress((prevValues) => ({
            ...prevValues,
            [e.target.name]: e.target.value,
        }));

        setSelectedCountry(countryVal);
    };

    const addCustomTag = (tag, key) => {
        var tagname = key ? key : tag.Name;
        setCustomTags((prevValues) => ({
            ...prevValues, [tagname]: tag
        }))
    }

    const removeCustomTag = (tagName) => {
        var newMap = { ...customTags };
        delete newMap[tagName]
        setCustomTags({ ...newMap })
    }




    useEffect(() => {
        updateAddress({
            addressee: address.addressee,
            street: address.street,
            city: address.city,
            zipcode: address.zipCode,
            state: address.state,
            phone: address.phone,
            email: address.email,
            country: address.country,
            name: address?.tags?.atag?.Name,
        });
        var keys = Object.keys(updateAddress).map((key) => { return key.toLowerCase() });
        let tags = {};
        if (address.tags !== undefined) {
            Object.keys(address.tags).forEach((tag) => {
                var name = address.tags[tag].Name;
                if (keys.indexOf(name.toLowerCase()) === -1 && tag.localeCompare("atag") !== 0)
                    tags[name] = address.tags[tag];
            })
        }
        setCustomTags(tags);

    }, [address]);

    useEffect(() => {
        const individualid = getInfo("IndividualID");
        //Get record by address id / beneficiary id
        GetSharesWith(individualid)
            .then((data) => {
                if (type === "address") {
                    let el = data.find((e) => e.addressID === contentid)
                    GetAddress(contentid, individualid, el.token, individualID).then((resp) => {
                        setAddress(resp);
                        const countryVal = countryLocaleList.find((o) => o.name === resp.country).value;
                        setSelectedCountry(countryVal);

                    })
                } else if (type === "stream") {
                    let el = data.find((e) => e.streamID === contentid)
                    const benId = el.tags?.RequestId ? el.beneficiaryID :""
                    setBeneficiaryId(benId)
                    GetSharedStream(contentid, el.token, benId, individualID).then((resp) => {
                        setStream(resp);
                        setToken(el.token);
                    })
                } else {
                    let el = data.find((e) => e.securedcontentID === contentid);
                    const benId = el.tags?.RequestId ? el.beneficiaryID :"";
                    GetSharedSecuredContent(contentid, el.token, benId, individualID).then((resp) => {
                        setSecuredcontent(resp);
                        const matchGlobalList = (spid, newtemplates) => {
                            newtemplates.forEach((id) => {
                                GetTemplateByID(id).then((gt) => {
                                    if(gt) {
                                    let newmatch = FirstMatchedTemplate(resp, [gt]);
                                    if (newmatch) {
                                        let temp = ProcessTemplate(newmatch);
                                        setMatchedTemplate(temp)
                                        let allfields = new Map();
                                        allfields.set(temp?.contentNameTag, { id: temp?.contentNameTag, label: temp?.contentNameTag, type: "alphanumeric" });
                                        if (temp?.tags) Object.values(temp.tags).forEach(tag => allfields.set(tag.Name, { id: tag.Name, label: tag.Name, type: "alphanumeric", placeholdertext: tag.Value }))
                                        setFields(fields => [...fields, ...allfields.values()])
                                        var con = {};
                                        if (resp && resp.tags)
                                            Object.values(resp.tags).forEach((t) => {
                                                con[t.Name] = t.Value;
                                            })
                                        setFilteredSC(con);
                                        var keys = [...allfields.values()].map((key) => { return key.id.toLowerCase() });
                                        var tags = {}
                                        if (resp && resp.tags && temp) {
                                            Object.keys(resp?.tags)?.forEach((tag) => {
                                                var name = resp?.tags[tag].Name;
                                                if (name !== temp?.contentTypeTag && keys.indexOf(name.toLowerCase()) === -1)
                                                  tags[name] = resp.tags[tag];
                                            })
                                        }
                                        setCustomTags(tags);
                                        return;
                                    }
                                }
                                }
                                ).catch(err => console.log('Error fetching templates for newtemplate ids ', id, err));
                            })
                        }
                        GetGlobalTemplatesList("", matchGlobalList);
                    })

                }

            }).catch(err => console.log(err))
        sessionStorage.removeItem('redirectto')
    }, [contentid, individualID, type])

    return type === "address" ?
        <div style={{ marginTop: "20px", marginLeft: "20px" }}>
            <div className="mx-auto max-w-screen-lg form-container p-5">
                <div>
                    <h1 className="py-2 form-label">
                        <AiOutlineProfile className="inline-icon" />
                        Address Details
                    </h1>
                    <h1 id="addrId">{address.id}</h1><CopyToClipboard elId="addrId" />
                </div>
                <fieldset disabled="disabled">
                    <div>
                        {address_locales[selectedCountry]
                            ? address_locales[selectedCountry].map((element) => {
                                return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                            })
                            : address_locales["default"].map((element) => {
                                return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                            })}
                    </div>
                    <div className="py-5"><Tags isReadOnly={true} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>
                </fieldset>
                <section className="grid grid-cols-12 gap-20 py-2">

                    <Link to="/">
                        <button className="button-style">Close</button>
                    </Link>
                </section>
            </div>
        </div>
        : type === "stream" ? <StreamDetails sharedStream={stream} isReadOnly={true} sharedBeneficiaryId={beneficiaryId} sharedToken={token} sharedIndId={individualID} />
            : <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                <div className="mx-auto max-w-screen-lg form-container p-5">
                    <div>
                        <h1 className="py-2 form-label">
                            <AiOutlineProfile className="inline-icon" />
                            {matchedtemplate?.Tags?.DisplayName?.Value || matchedtemplate?.Name} Details
                        </h1>
                        <h1 id="scId">{securedcontent?.id}</h1><CopyToClipboard elId="scId" />
                    </div>
                    <fieldset disabled="disabled">
                        <div>
                            {fields?.map((element) => {
                                return renderField(element.label, element.id, element.type, filteredSC[element.id], handleInputChange, element.options, "", false, element.placeholdertext);
                            })
                            }
                        </div>
                        <div className="py-5"><Tags isReadOnly={true} saveFn={addCustomTag} tags={customTags} deleteFn={removeCustomTag} /></div>
                    </fieldset>
                    <section className="grid grid-cols-12 gap-20 py-2">

                        <Link to="/">
                            <button className="button-style">Close</button>
                        </Link>
                    </section>
                </div>
            </div>


}
