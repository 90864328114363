import { useState } from "react";
import { IoMdShare } from "react-icons/io";
import { BsFillTrash3Fill, BsPlus } from "react-icons/bs";
import CopyToClipboard from "./CopyToClipboard";

function sharedWithName(sw, spDetails, benDetails) {
    return sw?.to !== "" ? <span className="text-pink-300">{sw.to}</span> : sw?.benId !== "00000000-0000-0000-0000-000000000000" ? <span className="text-teal-300">{benDetails[sw?.benId]?.tags?.name?.Value}</span> : sw?.spId !== "00000000-0000-0000-0000-000000000000" ? <span className="text-purple-300 ">{spDetails[sw.spId]?.tags?.name?.Value}</span> : ""
}

function RecordCard({ record, deleteClicked, shareClicked, spDetails, benDetails, viewClicked, type }) {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className="border border-gray-700 p-2 rounded-lg bg-gray-800 text-white text-sm">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span className="text-xs text-gray-400" id={"r_"+record.id}>{record.id}</span>
            <CopyToClipboard elId={"r_"+record.id} />
        </div>
      </div>
      <div className="flex justify-between items-center mt-1">
        <button onClick={() =>  viewClicked( record.id, type)} className="truncate flex-1 font-medium text-left text-blue-400 hover:underline">  {record.title} <span className="text-gray-400">{record.description}</span>
        </button>
        <span className="text-xs text-gray-200">{ record.updated ? "Updated " + record.updated : ""}</span>
      </div>
       <div className="flex justify-between items-center mt-2 border-t border-gray-600 pt-2">
        <div className="flex items-start gap-2 text-xs text-gray-300 whitespace-break-spaces flex-wrap">
        {record.sharedWith?.length > 0 ? (
            <span className="relative">
              <button onClick={() => setShowPopover(!showPopover)} className="text-left ">
                Shared with: <span className="hover:underline font-bold text-sm ">{sharedWithName(record.sharedWith[0], spDetails, benDetails)}</span>{record.sharedWith.length > 1 ? ` +${record.sharedWith.length - 1} users` : ''}
                </button>
              {showPopover && (
                <div className="absolute z-10 left-0 mt-2 w-72 bg-gray-700 p-2 rounded shadow-lg text-xs">
                  <ul>
                    {record.sharedWith.map(sw => { return <li key={sw.id} className="border-b border-gray-600 py-1 last:border-none" > {sharedWithName(sw, spDetails, benDetails)} <div className="text-xs text-gray-400 italic">{sw.id}</div></li> })}
                  </ul>
                </div>
              )}
            </span>
          ) : (
            <span>No shares</span>
          )}
          <button className="pt-0.5 text-blue-400 hover:underline flex items-center gap-1" onClick={() => shareClicked(record.id, record.title, type)}>
            <IoMdShare className="w-4 h-4" /> Share
          </button>
        </div>
        <button className="text-red-400 text-xs" onClick={() => deleteClicked(record.id, type)}>
          <BsFillTrash3Fill className="w-4 h-4" />
        </button>
      </div>
      
    </div> );
 
}

export default function ContentList({header, types, groupedRecords, totalCount, onDelete, onShare, onCreateNew, onCreateNewAll, onView, spDetails, benDetails}) {
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState(localStorage.getItem(document.location.hostname + header + '.type') || "All");

  return (
    <div className="px-4 py-3 max-w-screen-xl mx-auto p-4 space-y-4 bg-gray-900 text-white min-h-screen">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-bold">{header}</h2>
            {onCreateNewAll ? <button className="bg-blue-600 text-white px-4 py-2 text-sm font-semibold rounded-lg flex items-center gap-2 shadow-md hover:bg-blue-500" onClick={()=> onCreateNewAll()}>
              <BsPlus className="w-4 h-4" /> Create New
            </button> : <></>}
          </div>
          <div className="flex overflow-x-auto gap-2 pb-2">
            {types.map(type => (
              <button 
                key={type.Name} 
                onClick={() => { setSelectedType(type.Name); localStorage.setItem(document.location.hostname + header + '.type', type.Name)}} 
                className={`px-3 py-1 text-xs rounded-full whitespace-nowrap flex items-center gap-1 ${selectedType === type.Name ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
              >
                {type?.Icon ? type.Icon : "" }{(type?.Tags?.DisplayName?.Value || type?.Name)} 
                <span className="bg-gray-600 text-white text-xs font-semibold px-2 py-1 rounded-full">
                  {type.Name === "All" ? totalCount : (groupedRecords[type.Name] ? groupedRecords[type.Name].length : 0)}
                </span>
              </button>
            ))}
          </div>
          
          <input 
            type="text" 
            placeholder="Filter by title..." 
            value={search} 
            onChange={(e) => setSearch(e.target.value)} 
            className="border border-gray-600 p-2 rounded w-full mb-4 bg-gray-800 text-white"
          />
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

          {types.filter(type => type.Name !== "All" && (selectedType === "All" || selectedType === type.Name)).map(type => (
            <div key={type.Name} className="bg-gray-950 border border-gray-800 p-4 rounded-lg shadow-md space-y-4 ">
              <div className={`shadow-md p-2 border-b border-gray-700 flex justify-between items-center relative ${type.label ? 'text-yellow-400 ' : ' text-white'}`}>
                <span className="font-bold text-sm flex items-center gap-2">{type.Icon ? type.Icon : ""}{(type?.Tags?.DisplayName?.Value || type?.Name)}</span>
                <button className="border bg-gray-800 border-gray-600 px-3 py-1 text-xs rounded flex items-center gap-1 text-white" onClick={() => onCreateNew(type)}>
                  <BsPlus className="w-4 h-4" /> Create New
                </button>
              </div>
              <div className="space-y-2">
                {groupedRecords[type.Name] && groupedRecords[type.Name]
                  .filter(record => record?.title?.toLowerCase().includes(search.toLowerCase()))
                  .map((record) => (
                    <RecordCard key={record.id} record={record} viewClicked={onView} deleteClicked={onDelete} shareClicked={onShare} spDetails={spDetails} benDetails={benDetails} type={type}/>
                  ))}
              </div>
            </div>
          ))}</div>
        </div>);
}
