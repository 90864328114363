import DateTime from "./DateTimeDisplay";
import CopyToClipboard from "./CopyToClipboard.js";
import Search from "./Search.js"
import NotificationIcon from "./NotificationIcon.js"
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TiContacts } from "react-icons/ti"; 
import { getInfo } from "../utilities/calls.js";
export default function Header({val , viewState}) {

    const loginStatus = localStorage.getItem(document.location.hostname + '.loginStatus');
    const [userid, setUserid] = useState();
    const [guid, setGuid] = useState();
    const [view, setView] = useState(val);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    
  const toggleUserDD = () => {
      var dv = document.getElementById("user-dropdown");
      if (dv.classList.contains("hidden")) {
        dv.classList.add("block");
        dv.classList.remove("hidden");
      } else {
        dv.classList.add("hidden");
        dv.classList.remove("block");
      }
    }
  
    const changeView = (val) => {
      if(val === "I"){
        localStorage.setItem(document.location.hostname + '.view', 'I');
        setView('I');
      } else {
        localStorage.setItem(document.location.hostname + '.view', 'S');
        setView('S');
      }
      if(pathname === "/")
        navigate(0)
      else {
        navigate("/")  
      }
      viewState(val);
    }

    useEffect(()=> {
      const userId = (decodeURIComponent(getInfo("UserID")));
      setUserid(userId);
      setGuid(getInfo("IndividualID"))
    }, [])
  

    return <div class="max-w-screen-xl px-4 py-3 mx-auto grid  grid-cols-1 md:grid-cols-2 justify-between items-center">
    <div className="flex flex-row px-4 py-3">
      <div className="flex-shrink-0 order-1 ">
      <button onClick={toggleUserDD} type="button" class="py-2 rounded-lg md:me-0 focus:ring-4 focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
        <Link to="/"><img src="./justwhere.svg" alt="JustWhere" style={{ width: "2.5rem", height: "2.5rem" }} title="Securely Share, Track and Notify!" /></Link>
        </button>
        <div class="z-50 hidden my-4 text-base list-none divide-y rounded-lg shadow bg-gray-800 divide-gray-700" style={{ position: "absolute", top: "3rem" }} id="user-dropdown">
            <div class="px-4 py-3">
              <span class="block text-xs text-white">                    <span id="indiId">{`${guid || "Not logged in!"}`}</span> <CopyToClipboard elId="indiId" />
              </span>
              <span class="py-1 block text-xs truncate text-gray-200">{`${userid || "Not logged in!"}`}</span>

            </div>
            <ul class="py-2">
              <li class="px-4 text-xs text-gray-200"><DateTime /></li>
            </ul>
            </div>
      </div>
      {loginStatus === "LoggedIn" ? (
        <div className="flex flex-row order-2 p-2">
          <div class="inline-flex rounded-md shadow-sm" role="group">
          <button onClick={()=> changeView('I')} class={ view !== "S" ? "focus:ring-2 focus:ring-gray-600 border-gray-700 bg-green-400 hover:bg-green-600 text-green-800 hover:text-white px-2 py-1 md:px-4 md:py-2 text-sm border rounded-s-lg" : "focus:ring-2 focus:ring-gray-600 border-gray-700  hover:bg-gray-600 px-2 py-1 md:px-4 md:py-2 text-sm  border rounded-s-lg"}>Individual</button>
               
          <button onClick={()=> changeView('S')} class={ view === "S" ?  "focus:ring-2 focus:ring-gray-600 border-gray-700 bg-green-400  hover:bg-green-600 text-green-800 hover:text-white px-2 py-1 md:px-4 md:py-2 text-sm border rounded-e-lg" : "focus:ring-2 focus:ring-gray-600 border-gray-700  hover:bg-gray-600 px-2 py-1 md:px-4 md:py-2 text-sm border rounded-e-lg"}>Business</button>
          </div>
          
            <div className="p-2">
              {
                loginStatus === "LoggedIn" && <NotificationIcon/> 
              }
            </div>
            <div className="py-2 md:px-2 align-text-bottom	">
              {
                loginStatus === "LoggedIn" &&  <Link to="/contacts" className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-2">
                <TiContacts className="inline-icon"/>Contacts
             </Link>
              }
            </div>
            

          
        </div>
      ) : (<></>
      )}
    </div>
    <div className="flex px-4 py-3 md:order-2 order-3 col-span-3 md:col-span-1 justify-around rtl:space-x-reverse">
      {loginStatus === "LoggedIn" && view !== "S" ? <Search /> : <></>}
    </div>

  </div>
}