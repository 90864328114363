import { Link, useParams } from "react-router-dom";
import { GetServiceProvider, GetTemplatesByServiceProviderID, GetTemplateByID, DeleteTemplate } from "../utilities/calls";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "./CopyToClipboard";
import { BsFillTrash3Fill, BsClipboard2Check } from "react-icons/bs";
import DeleteModal from "./DeleteModal";
import {  displayWeblink, copyToClipboard } from "../utilities/functions";
import { CgTemplate } from "react-icons/cg";
import QRCodeModal from "./QRCodeModal";
import { AiOutlineQrcode } from "react-icons/ai";
import { FaLink } from "react-icons/fa";


export default function ServiceProviderTemplates() {

    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [spTemplates, setSpTemplates] = useState([]);
    const [templateDetails, setTemplateDetails] = useState({});
    const [sp, setSp] = useState();
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const { spid } = useParams();
    const [currId, setCurrId] = useState();
    const [qrcode, setQrcode] = useState();
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);


    const handleDeleteClick = (id) => {
        setCurrId(id);
        setConfirmationText("Are you certain you wish to delete this service provider?");
        setShowDeleteDlg(true);
    }
    useEffect(() => {
        GetServiceProvider(spid).then((spDetails) => { setSp(spDetails) }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
        });
        getTemplates();
        
    }, []);

    const getTemplates = () => {
        GetTemplatesByServiceProviderID(spid).then((templates) => {
            setSpTemplates(templates);
        }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
        });
    }

    useEffect(() => {
        spTemplates.forEach((spt) => {
            if (!templateDetails[spt])
                GetTemplateByID(spt).then(template => {
                    setTemplateDetails((templateDetails => ({ ...templateDetails, [spt]: template })))
                })
        })
    }, [spTemplates]);

    const del = () => {
        setShowDeleteDlg(false);

        DeleteTemplate(currId).then(() => {
            setShowAlert(true);
            setAlertText("Deleted successfully" );
            setSpTemplates([]);
            setTemplateDetails({});
            getTemplates();
            const timer = setTimeout(() => {
              setShowAlert(false);
            }, 2000);
        }).catch(err => {
            setAlertText("Error deleting a template.");
            setShowAlert(true);
            console.log('err', err)})
    }
    
    const showQRCode = (template) => {
        let linkForQR = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/connectsptemplate/' + spid + '/' + template.id;
        setQrcode(linkForQR);
        setIsQrModalOpen(true);
    };
  
    const copyShareLink = (template, e) => {
        document.getElementById('c2cicon_' + template.id).style.display = "";
        Object.values(templateDetails).forEach((spt) => {
            if(spt.id !== template.id){
                document.getElementById('c2cicon_' + spt.id).style.display = "none";
            }   
        })
        let shareLink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/connectsptemplate/' + spid + '/' + template.id;
        copyToClipboard(shareLink);  
    }

    return <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
        <h1 className="bold-text-input">Templates for  {`${sp?.tags?.name?.Value || "No Name"}`}</h1>

        <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={del} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
        {isQrModalOpen && (
				        <QRCodeModal
				          isOpen={isQrModalOpen}
				          onClose={() => {setIsQrModalOpen(false)}}
				          qrValue={qrcode}
				        />
				      )}
        <div style={{ marginTop: "20px" }}>
            {spTemplates.length === 0 ? <div className="w-full h-full flex items-start justify-start text-small">
                <p className="text-start pb-2">Templates help you collect the information you want from your consumers. </p></div> : <></>}
            <button className="button-style hover:bg-555">
                <Link to="/addtemplate/" state={{"spid":spid}} >
                <CgTemplate className="inline-icon" />
                Add Template</Link>
            </button>
            <div className="p-3"></div>
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>
            {Object.keys(templateDetails).length > 0 ? <ul class="w-full divide-y divide-gray-700 py-3">
                {Object.values(templateDetails).sort((a, b) => { return a.Name?.toLowerCase().localeCompare(b.Name?.toLowerCase()) }).map((template, index) => {
                    return <li class="pt-1 pb-3 sm:pb-4">

                        <div class="flex justify-between items-center space-x-4 rtl:space-x-reverse">

                            <p class="text-base font-semibold truncate text-blue-400">
                                <Link to={`/addtemplate/${template.id}`} state={{"spid":spid}} >

                                    {`${template.Tags?.DisplayName?.Value || template.name || template.Name || "No Name"}`}</Link>
                            </p>

                            <div class="inline-flex items-center text-white">
                                <button formMethod="dialog" formTarget="top" id="deleteTemplate" onClick={() => handleDeleteClick(template.id)} className="bg-slate-333 py-1 hover:bg-555">
                                    <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                </button>
                            </div>
                        </div>
                        <p class="mt-1 text-sm text-gray-400">
                            <span id={"template_" + index}>{template.id}</span><CopyToClipboard elId={"template_" + index} />
                        </p>
                        <div className="mt-2 text-blue-400">
                            {displayWeblink(template.URL)}
                        </div>
                        <button onClick={(e) => copyShareLink(template, e)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline "><FaLink className="inline-icon"/>Copy Share Link <BsClipboard2Check  fill="green" style={{display: "none"}} id={"c2cicon_" + template.id  } className="inline-icon" title="Copied to clipboard"/></button>
                        <button formMethod="dialog" formTarget="top" id={template.id} onClick={() => showQRCode(template)} className="text-blue-400 px-2 bg-slate-333 hover:bg-555 hover:underline py-1"> <AiOutlineQrcode className="inline-icon"/>QRCode Sharing</button>

                    </li>

                })
                }

            </ul> : <></>}
        </div>
    </div>
}