import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteStream, GetStreams, GetStream, GetSharesForStream, GetServiceProvider,GetBeneficiary,ConvertUnixTimestampToDateString } from "../utilities/calls";
import DeleteModal from "./DeleteModal";
import { displayDate, getSharesWith,getFileInfo } from "../utilities/functions";
import ShareModal from "./ShareModal";
import ContentList from "./ContentList";

export default function StreamList() {
    const navigate = useNavigate();
    const [streams, setStreams] = useState([]);
    const [streamDetails, setStreamDetails] = useState({});
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currId, setCurrId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [sharedCounts, setSharedCounts] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedStream, setSelectedStream] = useState();
    const [sharesWith, setSharesWith] = useState({});
    const [spDetails, setSpDetails] = useState({});
    const [benDetails, setBenDetails] = useState({});
    let timer = undefined;
 
    const loadStreams = useCallback(() => {
        let referredSpIds = [];
        let referredBenIds = [];
    
        GetStreams().then(resp => {
            setStreams(resp)
            const fetchCounts = async (streams) => {
                const sharesPromises = streams.map((stream) =>
                  GetSharesForStream(stream.ID)
                        .then((response) => {
                            let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
                            referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({...benList, [benId] : el}))).catch((err) => console.log(err)))
                            referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({...spList, [spId] : el}))).catch((err) => console.log(err)))

                            return { streamId: stream.ID, count: response.length, sharesWith : sharesWithArr };
                        })
                    .catch((error) => {
                      console.error(`Error shares:`, error);
                      setShowAlert(true);
                      setAlertText("Error fetching shares");
                    })
                );
                Promise.all(sharesPromises)
                  .then((details) => {
                    const cmap = {};
                    const sharesMap = {};
                    details.forEach(
                      (detail) => { if(detail) { cmap[detail.streamId] = detail.count; sharesMap[detail.streamId] = detail.sharesWith } }
                    );
                    setSharedCounts(cmap);
                    setSharesWith(sharesMap);
                  })
                  .catch((error) => {
                    console.error("Error setting record details:", error);
                    setShowAlert(true);
                    setAlertText("Error setting record details");
                  });
              };
              if (resp && resp.length > 0) {
                fetchCounts(resp);
              }
        }).catch(err => console.log('Error in fetch streams', err));
    },[]);
    useEffect(() => {
        loadStreams();
        return () => clearTimeout(timer);
    }, [loadStreams, timer])

  

    useEffect(() => {
        streams.forEach(stream => {
                GetStream(stream.ID).then(resp => {
                    setStreamDetails(prev => ({...prev,[stream.ID]: resp}));
             }).catch(err => console.log('Error getting stream details', err))
        })
    }, [streams]);

    const handleDeleteClick = (id) => {
        setCurrId(id);
        setShowDeleteDlg(true);
    }

    const del = () => {
        setShowDeleteDlg(false);

        DeleteStream(currId).then(() => {
            setShowAlert(true);
            setAlertText("Deleted successfully");
            setStreamDetails([]);
            setStreams([]);
            loadStreams();
            timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        }).catch(err => {
            setAlertText("Error deleting the document.");
            setShowAlert(true);
            console.log('err', err)
        })
    }

    const handleShareClick = (id) => {
        setSelectedStream(streams.find(s => s.ID === id));
        setShowShareModal(true);    
    }

    const handleViewClick = (id) => {
        navigate(`/documentdetails/${id}`, { state: {} })

    }

    const handleCreateNew = () => {
        navigate('/documentdetails/', {state: {}});
    }

    const groupedRecords = [];
    let types = [];
    Object.values(streamDetails)?.forEach((st) => {
        var fileinfo = getFileInfo(st?.tags?.__SYS_JW_Type?.Value)
        if(types.find(t => t.Name === fileinfo.Name) === undefined)
            types.push(fileinfo)    
    })
   
    types.forEach((type) => {
        const filteredStreams = Object.values(streamDetails)?.filter(a => getFileInfo(a?.tags?.__SYS_JW_Type?.Value).Name === type.Name);

        groupedRecords[type.Name] = filteredStreams.map((stream) => {
            return {
                id: stream.ID,
                title : stream.tags.Name.Value,
                description: stream.Description,
                shares: sharedCounts[stream.ID],
                sharedWith: sharesWith[stream?.ID],
                created: displayDate(ConvertUnixTimestampToDateString(stream.created)),
                updated: displayDate(ConvertUnixTimestampToDateString(stream.updated)),
                type: type.Name
            }
        })

    })
    
    types = types.sort((a, b) => { return groupedRecords[b.Name]?.length - groupedRecords[a.Name]?.length })
    types.unshift({Name: "All"});

    return (<>
            {showAlert ? (
                <div
                    class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                ><button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={() => { setShowAlert(false) }}>X</button>
                    <span class="text-sm">{alertText} </span>
                </div>
            ) : (
                <></>
            )}
            <DeleteModal confirmationText="Are you certain you wish to delete this secured content?" deleteLabel="Delete" onDeleteFn={del} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />
            {showShareModal && <ShareModal stream={selectedStream} onClose={() => { loadStreams();setShowShareModal(false); }} title={`${streamDetails[selectedStream.ID]?.tags?.Name?.Value || ""}`} />}
            <ContentList header={"Documents"} types={types} groupedRecords={groupedRecords} totalCount={streams.length} onDelete={handleDeleteClick} onShare={handleShareClick} onView={handleViewClick} onCreateNew={handleCreateNew} onCreateNewAll={handleCreateNew} spDetails={spDetails} benDetails={benDetails} /></>
        )
}