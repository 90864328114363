// src/components/Dashboard.js
import React, { useEffect, useState, useLayoutEffect } from "react";
import { AiFillCaretUp } from "react-icons/ai";
import {
  GetSentRequestsFrom,
  FetchIndividual,
  getInfo,
  GetShares,
  GetAccounts, GetAccount, GetSecuredContents
  } from "../utilities/calls";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import RecentActivity from "./RecentActivity";
import Login from "./Login";
import ServiceProviderLogin from "./ServiceProviderLogin";
import Footer from "./Footer";
import ServiceRequests from "./ServiceRequests";
import BusinessOnboarding from "./BusinessOnboarding";
import PromoBusinesses from "./PromoBusinesses";


const Dashboard = ({viewState, val}) => {
  const [individual, setIndividual] = useState({});
  const [requestsSentCount, setSentRequetsCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [securedContentsCount, setSecuredContentsCount] = useState(0);
  const [userId, setUserId] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const previousAddressesCount = useState(localStorage.getItem(document.location.hostname + ".addressesCount") + localStorage.getItem(document.location.hostname + ".securedContentsCount"));
  const previousSentRequestCount = useState(localStorage.getItem(document.location.hostname + ".sentRequestCount"));
  const previousSharesCount = useState(localStorage.getItem(document.location.hostname + ".sharesCount"));
  const [individualNotificationCount, setIndividualNotificationCount] = useState(localStorage.getItem(document.location.hostname + '.individualNotificationsCount') ? localStorage.getItem(document.location.hostname + '.individualNotificationsCount') : 0)
  useLayoutEffect(() => {
    setUserId(getInfo("UserID"));
  }, []);


  useEffect(() => {
    const individualid = getInfo("IndividualID");
    if (individualid == null) return;
    GetSecuredContents()
    .then((resp) => {
      const count = resp?.length || 0;
      setSecuredContentsCount(count)
      localStorage.setItem(document.location.hostname + ".securedContentsCount", count)
    }).catch((err) => {
      console.error("Error fetching secured contents:", err);
      setShowAlert(true);
      setAlertText("Error fetching secured content");
    })
    FetchIndividual(individualid)
      .then((individualData) => {
        // Set the retrieved individual in the state
        setIndividual(individualData);
        const count = Object.keys(individualData.addresses || {}).length;
        localStorage.setItem(document.location.hostname + ".addressesCount", count);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual");
      });

    GetSentRequestsFrom(individualid)
      .then((response) => {
        const count = response?.length || 0;
        setSentRequetsCount(count);
        localStorage.setItem(document.location.hostname + ".sentRequestCount", count);
      })
      .catch((error) => {
        console.error("Error fetching requests:", error);
        setShowAlert(true);
        setAlertText("Error fetching requests");
      });

    GetShares(individualid)
      .then((sharesdata) => {
        const count = sharesdata?.length || 0;
        setShareCount(count);
        localStorage.setItem(document.location.hostname + ".sharesCount", count);
      })
      .catch((error) => {
        console.error("Error fetching shares:", error);
        setShowAlert(true);
        setAlertText("Error fetching shares");
      });
    
 }, []);
 useEffect(() => {
    const timer = setInterval(() => {
      setIndividualNotificationCount(localStorage.getItem(document.location.hostname + '.individualNotificationsCount'))
    }, 3600);
    return () => {
      clearInterval(timer)
    }
  }, [])


  useEffect(()=> {
      document.getElementsByTagName("nav")[0].style.display = "";
  },[val]);

  return userId ? (
    <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
      <div class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
        role="alert"
        style={{ display: showAlert ? "block" : "none" }}
      >
                        <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
      </div>
      {val === "S" 
      ?
        <></>
      : <>
          <PromoBusinesses/>
          <div className="container grid grid-cols-1 md:grid-cols-3 gap-4">
            <Link className="dashboard-tile hover:bg-eee " style={{ display: "block" }} to="/securedContents" title="Secured Contents">
              <div>
                <h2># of Secured Contents</h2>
                <p>{securedContentsCount + Object.keys(individual.addresses || {}).length}</p>
                <p>{changeIndicator(previousAddressesCount, securedContentsCount + Object.keys(individual.addresses || {}).length)}</p>
              </div>
            </Link>
            <Link className="dashboard-tile hover:bg-eee " style={{ display: "block" }} to="/privateserviceproviders" title="My Service Providers">
              <div>
                <h2># of Private Service Providers</h2>
                <p>{Object.keys(individual.privateserviceproviders || {}).length}</p>
                <p></p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/sharing"
              title="Sharing"
            >
              <div>
                <h2># of Secured Contents Shared with Others</h2>
                <p>{shareCount}</p>
                <p>{changeIndicator(previousSharesCount, shareCount)}</p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/individualnotifications"
              title="Notifications"
            >
              <div>
                <h2>Notifications Received</h2>
                <p>{individualNotificationCount}</p>
              </div>
            </Link>
            <Link
              className="dashboard-tile hover:bg-eee" style={{ display: "block" }}
              to="/requests"
              title="Requests"
            >
              <div>
                <h2>Requests Sent</h2>
                <p>{requestsSentCount}</p>
                <p>{changeIndicator(previousSentRequestCount, requestsSentCount)}</p>
              </div>
            </Link>
          </div>
        </> 
      }
      {val === "S" ?
        <ServiceRequests /> 
        :<RecentActivity />
      }
      <Footer />
    </div>
  ) : (
    <Login />
  );
};


function changeIndicator(prevCount, currCount) {
  const curr = Number(currCount), prev = Number(prevCount);
  if (curr > prev) return <div> <AiFillCaretUp className="inline-icon" />
    {curr - prev} new updates since last time.</div>;
  return (<div></div>)
}

export default Dashboard;
