import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate, createSearchParams } from "react-router-dom";

export default function Search() {
    const navigate = useNavigate();


    const search = (e) => {
        let val = document.getElementById("search");
        navigate({
            pathname: "search",
            search: createSearchParams({
                text: [val.value]
            }).toString()
        });
    }

    const searchKey = (e) => { 
        if(e.key === 'Enter')
        search() 
    }


    return <div class="relative w-full">
        <input type="search" onKeyUp={searchKey} id="search" class="block w-full form-input" style={{"margin-bottom": "0", "padding-top":"0.5rem"}} placeholder="Search Businesses" required />
        <button type="submit" onKeyUp={searchKey} onClick={search} class="text-white absolute end-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" style={{ bottom: ".3rem" }}><AiOutlineSearch className="inline-icon" /> Search</button>
    </div>

}
