import * as dayjs from "dayjs";
import { BsFillTrash3Fill , BsCircle, BsClockFill, BsCheckCircleFill } from "react-icons/bs";
import {  FaUserCircle, FaRegFile, FaRegFilePdf, FaRegFileWord, FaRegFileExcel, FaRegFileImage, FaRegFileVideo, FaRegFileAudio, FaRegFileCode, FaRegFileArchive } from "react-icons/fa";

function renderField(label, id, type, val, onChange, options, tooltip, readonly, placeholdertext) {
  switch (type) {
    case "select":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <select onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input">
            {options.map((element) => {
              return <option value={element.value}>{element.name}</option>;
            })}
          </select>
        </div>
      );
    case "alphanumeric":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <input readOnly={readonly} type="text" onChange={onChange} id={id} name={id} value={val} placeholder={placeholdertext ? placeholdertext : label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
    case "textarea":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <textarea onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
    case "checkbox":
      return (
        <div className="grid grid-cols-12">
          <input type="checkbox" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-1 py-2 px-2 form-input" />
          <label className="col-span-11 form-label px-2" htmlFor={id}>
            {label}
          </label>
          <label class="px-1 col-span-12 form-label">{tooltip ? tooltip : <></>}</label>
        </div>
      );
    case "fileupload":
      return (
        <div className="grid grid-cols-12">
          <label className="col-span-12 form-label" for="file_input">{label}</label>
          <input className="block col-span-12 form-input text-smrounded-lg cursor-pointer " id="file_input" type="file" onChange={onChange} />
        </div>
      );

    default:
      return (
        <div className=" grid grid-cols-12">
          <label className="col-span-12 form-label" htmlFor={id}>
            {label}:
          </label>
          <input type="text" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input" />
        </div>
      );
  }
}


function renderTag(label, id, val, isPrivate, onChange, onDelete, readonly) {
  return (
    <fieldset disabled={readonly ? "disabled" : ""}>
    <div className="grid grid-cols-12 border-0 border-gray-700 border-b-2 py-2" id={id}>
      <label className="col-span-11 form-label" htmlFor={id}>
        Name:
      </label>
      {readonly ? <span className="col-span-1"></span> : <button formMethod="dialog" formTarget="top" name={id} className="col-span-1 bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
        <BsFillTrash3Fill name={id} title="Delete" className="inline-icon" />
      </button>}
      <input type="text" onChange={onChange} id={"name_" +id} name="name" value={label} placeholder="Name" className="col-span-12 px-2 py-2 form-input" />

      <label className="col-span-12 form-label" htmlFor={id}>
        Value:
      </label>
      <textarea onChange={onChange} id={"value_" + id} name="value" value={val} placeholder="Value" className="col-span-12 px-2 py-2 form-input" />
      
      <input type="checkbox" onChange={onChange} id={"private_" + id} name={id} checked={isPrivate} placeholder={label} className="col-span-1 py-2 px-2 form-input" />
      <label className="col-span-11 form-label px-2" htmlFor={id}>Private (Only visible to you.)</label>
      
    </div>
    </fieldset>
  );
}

function renderData(label, val) {
  return (
    <div className="grid grid-cols-12">
      <label className="col-span-5 form-label text-right">
        {label}:
      </label>
      <span className="col-span-1" />
      <label className="col-span-6 text-left" >{val}</label>
    </div>
  );
}

function displayDate(val){
  return dayjs().diff(dayjs(val), "week") > 1 ? " on " +dayjs(val).format("MMM D, YYYY")  : dayjs(val).fromNow()
}
function sanitizeEmail(email) {
  return email.trim().toLowerCase();
}

function displayPhoneLink(val) {
  if(val === undefined || val === null) return "";
  const tel = val.replaceAll(' ', '');
  return <a href={"tel:" + tel}>{val}</a>
}

function displayWeblink(val) {
  if(val === undefined || val === null) return "";
  const http= "http://";
  const https="https://";
  if(!val.toLowerCase().startsWith(http) && !val.toLowerCase().startsWith(https)){
    return <a href={https + val} target="_blank" rel="noopener noreferrer">{val}</a>
  }
  return <a href={val} target="_blank" rel="noopener noreferrer">{val}</a>
}

function displayEmaillink(val){
  if(val === undefined || val === null) return "";
  if(isMobile()) 
    return <a href={"mailto:" + val} target="_blank" rel="noopener noreferrer">{val}</a>
  else 
  return <a href={"https://mail.google.com/mail/u/0/?fs=1&tf=cm&to="  + val} target="_blank" rel="noopener noreferrer">{val}</a>
}

function displayLogoURL(url) {
  if(url === undefined || url === null || url.trim() === "") return <FaUserCircle className="w-6 h-6"/>
  const http= "http://";
  const https="https://";
  if(!url.toLowerCase().startsWith(http) && !url.toLowerCase().startsWith(https)){
    url = https+ url;
  }
  return <img src={url} alt={url} style={{maxHeight:"120px", maxWidth:"120px"}}/>
}

function isMobile() {
	const useragent = navigator.userAgent.toLowerCase();
	console.log("Client is using user Agent %s", useragent)
	return useragent.includes("android") || useragent.includes( "iphone") || useragent.includes("ipad")
  }

  function RequestStatus({ status, title }) {
    switch (status) {
      case "ACCEPTED":
        return <BsCheckCircleFill fill="green" title="ACCEPTED"/>;
      case "NEW":
        return <BsCircle   fill="green" title="NEW"/>;
      case "WAITING":
        return (
          <div>
            <BsCircle fill="green" title="NEW" className="inline-icon"  />
            <BsClockFill
              style={{
                display: "inline",
                verticalAlign: "middle",
              }}
              title={title}
            />
          </div>
        );
      default:
        return "";
    }
  }

  function createFormDataForSelectedFile(e) {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append(
        'file',
        selectedFile,
        selectedFile.name
    );
    formData.append('name', selectedFile.name)
    formData.append('type', selectedFile.type)
    return formData;
  }

  function handleAccordionClick(ev) {
    let dv, icon;
    if (ev.target.dataset && ev.target.dataset.accordionTarget) {
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if (dv?.classList?.contains("hidden")) {
      dv.classList.add("block");
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }

function getSharesWith(records, referredSpIds, referredBenIds) {
  let sharesWithArr = [];
  records.forEach(share => {
    let sharewith = {};
    sharewith.id = share.beneficiaryID !== "00000000-0000-0000-0000-000000000000" ? share.beneficiaryID : share.serviceProviderID 
    sharewith.to = share?.tags?.SharedWithEmail ? share.tags.SharedWithEmail.Value : "";
    sharewith.spId = share.serviceProviderID;
    if (share.serviceProviderID !== "00000000-0000-0000-0000-000000000000" && referredSpIds.findIndex((v) => v === share.serviceProviderID) === -1) {
      referredSpIds.push(share.serviceProviderID);
    }
    sharewith.benId = share.beneficiaryID;
    if (!share?.tags?.SharedWithEmail && share.beneficiaryID !== "00000000-0000-0000-0000-000000000000" && referredBenIds.findIndex((v) => v === share.beneficiaryID) === -1) {
      referredBenIds.push(share.beneficiaryID);
    }
    sharesWithArr.push(sharewith);
  });
  return sharesWithArr;
}


function renderSharesWith(label, id, scount, swith, showfn, hidefn, spDetails, benDetails) {
  return <>
    <button data-popover-target={label + id} onClick={() => showfn(id)}><span class=" text-sm font-medium mx-2 px-2.5 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400"> {scount ? scount : "0"} Shares </span></button>
    <div data-popover id={label + id} role="tooltip" class="mt-8 sm:left-10 md:ml-24 absolute z-10 invisible inline-block text-sm transition-opacity duration-300 border rounded-lg shadow-xs w-72 bg-gray-800 border-gray-600 text-amber-200">
      <button className="absolute top-2 right-1 text-lg font-normal text-gray-500 -translate-x-2 " onClick={() => hidefn(id)}>X</button>
      {scount && scount > 0 ? <div class="p-3 space-y-2" >
        <h3 class="font-semibold text-white">Shared With</h3>
        {swith ? swith.map(sw => { return <p>{sw?.to !== "" ? sw.to : sw?.benId !== "00000000-0000-0000-0000-000000000000" ? <span className="text-teal-200">{benDetails[sw?.benId]?.tags?.name?.Value}</span> : sw?.spId !== "00000000-0000-0000-0000-000000000000" ? <span className="text-purple-300">{spDetails[sw.spId]?.tags?.name?.Value}</span> : ""}<div className="text-xs text-gray-400 italic">{id}</div></p> }) : "No shares yet."}
      </div> : <h3 class="py-3 px-1 font-semibold text-white">No shares yet.</h3>
      }
    </div></>
}


const fileTypeIcons = {
  "application/pdf": { Icon: <FaRegFilePdf className="text-red-400 w-4 h-4" />, Name: "PDF Document" }, 
  "application/msword": { Icon: <FaRegFileWord className="text-blue-400 w-4 h-4" />, Name: "Word Document" },
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": { Icon: <FaRegFileWord className="text-blue-400 w-4 h-4" />, Name: "Word Document" },
  "application/vnd.ms-excel": { Icon: <FaRegFileExcel className="text-green-400 w-4 h-4" />, Name: "Excel Spreadsheet" },
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": { Icon: <FaRegFileExcel className="text-green-400 w-4 h-4" />, Name: "Excel Spreadsheet" },
  "image/jpeg": { Icon: <FaRegFileImage className="text-yellow-400 w-4 h-4" />, Name: "JPEG Image" },
  "image/png": { Icon: <FaRegFileImage className="text-yellow-400 w-4 h-4" />, Name: "PNG Image" },
  "image/gif": { Icon: <FaRegFileImage className="text-yellow-400 w-4 h-4" />, Name: "GIF Image" },
  "video/mp4": { Icon: <FaRegFileVideo className="text-purple-400 w-4 h-4" />, Name: "MP4 Video" },
  "video/x-msvideo": { Icon: <FaRegFileVideo className="text-purple-400 w-4 h-4" />, Name: "AVI Video" },
  "audio/mpeg": { Icon: <FaRegFileAudio className="text-pink-400 w-4 h-4 " />, Name: "MP3 Audio" },
  "audio/wav": { Icon: <FaRegFileAudio className="text-pink-400 w-4 h-4" />, Name: "WAV Audio" },
  "application/zip": { Icon: <FaRegFileArchive className="text-gray-400 w-4 h-4" />, Name: "ZIP Archive" },
  "application/x-rar-compressed": { Icon: <FaRegFileArchive className="text-gray-400 w-4 h-4" />, Name: "RAR Archive" },
  "text/javascript": { Icon: <FaRegFileCode className="text-yellow-300 w-4 h-4" />, Name: "JavaScript File" },
  "text/html": { Icon: <FaRegFileCode className="text-orange-400 w-4 h-4" />, Name: "HTML File" },
  "text/css": { Icon: <FaRegFileCode className="text-blue-300 w-4 h-4" />, Name: "CSS File" },
};

const getFileInfo = (fileType) => {
  return fileTypeIcons[fileType] || { Icon: <FaRegFile className="text-gray-400 w-4 h-4" />, Name: "Unknown File" };
};


function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
        .then(() => {
            alert('Text copied to clipboard!');
        })
        .catch((error) => {
            alert('Failed to Copy text into clipboard. Error: ' + error);
        });
}

function isValidImage(url) {
  
    return new Promise((result) => {
        if (!url) {
          result(true); // Treat empty URL as valid
          return;
        }

        const img = new Image();
        img.onload = () => result(true);   // Image loaded successfully
        img.onerror = () => result(false); // Image failed to load
        img.src = url;
    });
}
   
export { renderField, renderTag, renderData , displayDate,sanitizeEmail, isMobile, displayPhoneLink, displayWeblink, displayEmaillink, displayLogoURL, RequestStatus, handleAccordionClick, createFormDataForSelectedFile, renderSharesWith, getSharesWith, getFileInfo, copyToClipboard, isValidImage}
