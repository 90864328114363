import { SearchBeneficiaries, SearchSP, getInfo, CreatePrimaryToken, CreateSecondaryToken, GetJustWhereUsers, GetVisitLabelByToken, } from "../utilities/calls";
import { useEffect, useState } from "react";
import { serviceCategoriesList } from "../utilities/templates";
import { displayEmaillink, displayPhoneLink, displayWeblink, sanitizeEmail, copyToClipboard} from "../utilities/functions";
import { AiOutlinePhone, AiOutlineGlobal, AiOutlineMail, AiOutlineSearch, AiFillCheckCircle } from "react-icons/ai";
import { FaShareAlt, FaLink } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter } from "react-icons/md";
import QRCodeModal from "./QRCodeModal";




export default function ShareModal({ content, onClose, address, stream, title }) {
    const [resultsSP, setResultsSP] = useState([]);
    const [resultsBen, setResultsBen] = useState([]);
    const [selectedBen, setSelectedBen] = useState([]);
    const [selectedSP, setSelectedSP] = useState([]);
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [selected, setSelected] = useState([]);
    const [selectedShareWith, setSelectedShareWith] = useState('B');
    const [userEmail, setUserEmail] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [suggestedEmail, setSuggestedEmail] = useState('');
    const [sendBtn, setSendBtn] = useState(false)
    const [contineAndSendBtn, setContinueAndSentBtn] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [visitLink, setVisitLink] = useState();

    const handleCloseModal = () => setModalOpen(false);



    const toggleBenCheck = (e) => {
        if (e.target.checked) {
            setSelectedBen((selectedBen) => [...selectedBen, e.target.id])
            setSelected((selected) => [...selected, e.target.dataset.name]);
        } else {
            setSelectedBen((selectedBen) => ([...selectedBen.filter(ben => ben !== e.target.id)]))
            setSelected((selected) => ([...selected.filter(s => s !== e.target.dataset.name)]))
        }
    }

    const toggleSPCheck = (e) => {
        if (e.target.checked) {
            setSelectedSP((selectedSP) => [...selectedSP, e.target.id])
            setSelected((selected) => [...selected, e.target.dataset.name]);
        } else {
            setSelectedSP((selectedSP) => ([...selectedSP.filter(ben => ben !== e.target.id)]))
            setSelected((selected) => ([...selected.filter(s => s !== e.target.dataset.name)]))

        }
    }
    const handleInputChange = (event) => {
        setUserEmail(event.target.value)
        setSendBtn(true);
        setContinueAndSentBtn(false);
        setConfirmationMessage(false)
    };

    const updateList = (search) => {
        SearchSP(search).then((resp) => { setResultsSP(resp) }).catch((err) => { console.log("Error search sp", err) })
        SearchBeneficiaries(search).then((resp) => {  setResultsBen(resp) }).catch((err) => { console.log("Error search ben", err) })
    }
    useEffect(() => {
        const s = "";
        updateList(s);
    }, []);

    const share = () => {
        const def = getInfo("DefaultServiceProvider")
        const individualid = getInfo("IndividualID");
        selectedBen.forEach((benid) => {
            if (address) {
                CreatePrimaryToken(def, address.id, individualid, "").then((resp) => {
                    CreateSecondaryToken(def, benid, resp.token).then(() => {
                        setShowAlert(true);
                        setAlertText("Address shared with Beneficiary");
                        setSelectedBen(prev => [...prev.filter(el => el !== benid)])
                    }).catch(e => {
                        console.error("Error contacting Beneficiary", e);
                        setAlertText("Error contacting Beneficiary, please try again!");
                        setShowAlert(true)
                    })
                }).catch((err) => {
                    console.error("Error contacting Beneficiary", err);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })


            } else if (content) {

                CreatePrimaryToken(def, "", individualid, content.id).then((resp) => {
                    CreateSecondaryToken(def, benid, resp.token).then(() => {
                        setShowAlert(true);
                        setAlertText("Content shared with Beneficiary");
                        setSelectedBen(prev => [...prev.filter(el => el !== benid)])
                    }).catch(e => {
                        console.error("Error contacting Beneficiary", e);
                        setAlertText("Error contacting Beneficiary, please try again!");
                        setShowAlert(true)
                    })
                }).catch((err) => {
                    console.error("Error contacting Beneficiary", err);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })
            } else {
                //stream
                CreatePrimaryToken(def, "", individualid, "", stream.ID).then((resp) => {
                    CreateSecondaryToken(def, benid, resp.token).then(() => {
                        setShowAlert(true);
                        setAlertText("Document shared with Beneficiary");
                        setSelectedBen(prev => [...prev.filter(el => el !== benid)])
                    }).catch(e => {
                        console.error("Error contacting Beneficiary", e);
                        setAlertText("Error contacting Beneficiary, please try again!");
                        setShowAlert(true)
                    })
                }).catch((err) => {
                    console.error("Error contacting Beneficiary", err);
                    setAlertText("Error contacting Beneficiary, please try again!");
                    setShowAlert(true)
                })
            }
        })

        selectedSP.forEach((spid) => {
            if (address) {
                CreatePrimaryToken(spid, address.id, individualid, "").then(() => {
                    setShowAlert(true);
                    setAlertText("Address shared with Service Provider");
                    setSelectedSP(prev => [...prev.filter(el => el !== spid)])

                }).catch((err) => {
                    console.error("Error contacting Service Provider", err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            } else if (content) {

                CreatePrimaryToken(spid, "", individualid, content.id).then(() => {
                    setShowAlert(true);
                    setAlertText("Content shared with Service Provider");
                    setSelectedSP(prev => [...prev.filter(el => el !== spid)])

                }).catch((err) => {
                    console.error("Error contacting Service Provider", err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            } else {
                //stream
                CreatePrimaryToken(spid, "", individualid, "", stream.ID).then(() => {
                    setShowAlert(true);
                    setAlertText("Document shared with Service Provider");
                    setSelectedSP(prev => [...prev.filter(el => el !== spid)])

                }).catch((err) => {
                    console.error("Error contacting Service Provider", err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            }
        })
    }
    const search = (e) => {
        let val = document.getElementById("searchmodal");
        updateList(val.value);
    }

    const searchKey = (e) => {
        if (e.key === 'Enter')
            search()
    }

    const shareWith = (val) => {
        if (selectedShareWith !== val) {
            document.getElementById('shareWith_' + selectedShareWith).classList.remove('text-white', 'bg-gray-950', 'border-gray-500', 'border', 'border-b-0');
            setSelectedShareWith(val);
        }
        document.getElementById('shareWith_' + val).classList.add('text-white', 'bg-gray-950', 'border-gray-500', 'border', 'border-b-0');
    }

    const generateQR = (email) => {
        GetJustWhereUsers([email]).then((resp) => {
            if (resp.validUserIds?.length === 0) {
                const updatedUserEmail = sanitizeEmail(userEmail)
                GetJustWhereUsers([updatedUserEmail]).then((resp) => {
                    if (resp.validUserIds?.length > 0) {
                        setSuggestedEmail(resp.validUserIds[0])
                        setConfirmationMessage(true)
                        setContinueAndSentBtn(true)
                        setShowAlert(false);
                        setSendBtn(false)
                    }
                    else {
                        setConfirmationMessage(false)
                        setContinueAndSentBtn(false)
                        setSendBtn(true)
                        setShowAlert(true);
                        setAlertText(userEmail + " is not a JustWhere user, you can only offer address to existing JustWhere User.");
                    }
                }).catch((err) => {
                    console.log(err);
                    setShowAlert(true);
                    setAlertText("Error occurred while verifiying justwhere user,please continue after some time");
                })
            } else {
                const individualid = getInfo("IndividualID");
                const addressid = address ? address.id : "";
                const streamid = stream ? "jrn:stream:" + stream.ID : "";
                const contentid = content ? "jrn:securedcontent:" + content.id : ""
                CreatePrimaryToken("", addressid, individualid, contentid, streamid).then((resp) => {
                    CreateSecondaryToken("", email, resp.token).then((resp) => {
                        GetVisitLabelByToken(resp.token).then((resp) => {
                            const link = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp;
                            setVisitLink(link);
                        }
                        ).catch((err) => console.log('err', err));
                        setSendBtn(false)
                    }).catch((err) => {
                        console.log(err)
                        setAlertText(err?.response?.data);
                        setShowAlert(true);
                    })
                }).catch((err) => {
                    console.log(err);
                    setAlertText("Error contacting Service Provider, please try again!");
                    setShowAlert(true)
                })
            }
        }).catch((err) => { console.log(err) })

    }


    return <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
        <div className='w-11/12 min-h-[450px] max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>
            <div className="py-3 px-2">
                <button className="py-1 absolute top-2 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={onClose}>X</button>


                <div class="p-1 inline-flex rounded-md shadow-sm" role="group">
                    <h1 className="form-label">
                        Share {title || ""} <span className="font-thin italic text-xs">{address ? address.id : stream ? stream.ID : content.id}</span> with
                    </h1>
                </div>
                <ul class="flex flex-wrap text-sm font-medium text-center border-b  border-gray-500 text-gray-400">

                    <li class="me-2" >
                        <button id="shareWith_B" onClick={() => { shareWith('B') }} class="inline-block p-4 rounded-t-lg  bg-gray-950  text-white border-gray-500 border border-b-0">  <MdOutlineBusinessCenter className="inline-icon" style={{ height: "1rem", width: "1rem" }} />  Business
                        </button>
                    </li>
                    <li class="me-2">
                        <button id="shareWith_I" onClick={() => { shareWith('I') }} aria-current="page" class="inline-block p-4 rounded-t-lg">  <IoPersonCircleOutline className="inline-icon" style={{ height: "1rem", width: "1rem" }} />Individual
                        </button>
                    </li>

                </ul>
                {selectedShareWith === 'B' ?
                    <><div class="relative w-full p-2">
                        <input type="search" onKeyUp={searchKey} id="searchmodal" class="block w-full form-input" style={{ "margin-bottom": "0", "padding-top": "0.5rem" }} placeholder="Search Businesses" />
                        <button type="submit" onKeyUp={searchKey} onClick={search} class="text-white absolute end-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1 me-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" style={{ bottom: ".8rem" }}><AiOutlineSearch className="inline-icon" /> Search</button>
                    </div>

                        <div class="w-full border border-gray-700 border-x-0 max-h-48 p-4 overflow-y-auto ">

                            <ul class="w-full divide-y divide-gray-700">
                                {
                                    resultsSP?.map((r, index) => {
                                        return <li class="py-3 sm:py-4" >

                                            <div className=" flex items-center ps-2">
                                                <input name="spCheck" onChange={toggleSPCheck} data-name={r.tags?.name?.Value} id={r.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500" />

                                                <button class="p-1 text-sm md:font-medium font-semibold truncate">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : ""} <span class="font-normal text-gray-100 text-xs italic"> Service Provider</span></button>
                                            </div>
                                            <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                            {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value)?.name || ""}</p> : <></>}
                                            {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                                {r.tags?.description?.Value}
                                            </p> : <></>}
                                            {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                            </p> : <></>}
                                            {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                            </p> : <></>}
                                            {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.country?.Value}
                                            </p> : <></>}
                                            {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon" />{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                            {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon" />{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                            {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon" />{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                        </li>
                                    })
                                }
                                {
                                    resultsBen?.map((r, index) => {
                                        return <li class="py-3 sm:py-4 " >

                                            <div className=" flex ps-2 items-center">
                                                <input name="benCheck" onChange={toggleBenCheck} data-name={r.tags?.name?.Value} id={r.id} type="checkbox" value="" class="w-4 h-4 text-blue-600 rounded focus:ring-blue-600 ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500" />

                                                <button class="p-1 text-sm md:font-medium font-semibold truncate">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : ""} <span class="font-normal text-gray-100 text-xs italic"> Beneficiary</span></button>
                                            </div>
                                            <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                            {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value)?.name || ""}</p> : <></>}
                                            {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                                {r.tags?.description?.Value}
                                            </p> : <></>}
                                            {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                            </p> : <></>}
                                            {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                            </p> : <></>}
                                            {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                                {r.tags?.country?.Value}
                                            </p> : <></>}
                                            {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon" />{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                            {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon" />{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                            {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon" />{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                        </li>
                                    })
                                }
                            </ul>

                        </div>
                        <p className="px-2 py-1">{selected.join(", ")}</p>
                        <div className="flex items-start justify-start space-x-4 p-3 "><button disabled={selectedBen.length === 0 && selectedSP.length === 0} onClick={share} class="button-style positive-action">
                            <FaShareAlt className="inline-icon" />Share</button>
                            <button onClick={onClose} className="button-style">Close</button>

                        </div>
                    </> : <div className=" grid grid-cols-1">


                        <div className="mt-2 col-span-12 form-input px-2 py-2">
                            <input
                                type="text"
                                value={userEmail}
                                onChange={handleInputChange}
                                placeholder="Enter Email"
                                className="col-span-12 form-input px-2 py-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            {
                                confirmationMessage &&
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-medium text-yellow-400">User with email "{userEmail}" is not registered with us but <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span>{" "}is a JustWhere User.</p>
                                    <p className="text-sm font-medium text-yellow-400">Click on continue and share if you wish to share with <span className="text-blue-500 hover:underline">"{suggestedEmail}"</span></p>
                                </div>
                            }
                            <div class="flex justify-start items-start space-x-4 py-2 ">
                                {
                                    contineAndSendBtn &&
                                    <button onClick={() => generateQR(suggestedEmail)} type="submit" className="button-style positive-action">
                                        Continue And Share
                                    </button>
                                }
                                {sendBtn &&
                                    <button onClick={() => generateQR(userEmail)} type="submit" className="button-style positive-action">
                                        Share
                                    </button>
                                }
                                {sendBtn === true || contineAndSendBtn === true ? (
                                    <button onClick={onClose} className="button-style">Cancel</button>
                                ) : (
                                    <button onClick={onClose} className="button-style">Close</button>
                                )}
                            </div>

                            {visitLink ? <p>Shared with {suggestedEmail ? suggestedEmail : userEmail} successfully.
                                <button onClick={() => { copyToClipboard(visitLink); document.getElementById("checked").style.display = ""; }}
                                    className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline font-bold">
                                    <FaLink className="inline-icon" />Copy Share Link
                                </button>
                                <span className="text-sm text-green-300" id="checked" style={{ display: "none" }} >
                                    <AiFillCheckCircle color="green" style={{ "padding-left": "0.1rem" }} className=" inline-icon" />Copied to clipboard.
                                </span>
                                <p class="mt-1 italic text-gray-200 text-xs"> The link is valid for 24 hours</p>
                                <button className="mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={() => setModalOpen(true)}>Show QR Code</button></p> : <></>
                            }{isModalOpen && (
                                <QRCodeModal
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                    qrValue={visitLink}
                                />
                            )}

                        </div>
                    </div>}
                <div
                    class=" w-full bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                >                <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
                    <span class="text-sm">{alertText} </span>
                </div>

            </div>

        </div>
    </div>
}