import { useEffect, useState, useLayoutEffect } from "react";
import { GetAccounts, GetAccount, ConvertUnixTimestampToDateString, GetSentRequestsFromBeneficiary, GetSentRequestsFromServiceProvider, GetBeneficiary, GetServiceProvider, getInfo, DeleteRequest, GetRequestDetails, GetSharesWithServiceProvider } from "../utilities/calls";
import * as dayjs from "dayjs";
import * as relativetime from "dayjs/plugin/relativeTime";
import Login from "./Login";
import CopyToClipboard from "./CopyToClipboard";
import { RequestStatus, isMobile } from "../utilities/functions";
import { Link } from "react-router-dom";
import { AiOutlineProfile, AiOutlineMail } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";

dayjs.extend(relativetime);

export default function ServiceProviderRequests() {

    const [userId, setUserId] = useState();
    const [state, setState] = useState(0);
    const [requestsForSP, setRequestsForSP] = useState({});
    const [requestsForBen, setRequestsForBen] = useState({});
    const [serviceProviderList, setServiceProviderList] = useState({});
    const [beneficiaryList, setBeneficiaryList] = useState({});
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [tokens, setTokens] = useState({})
    let timer = undefined;


    useLayoutEffect(() => {
        setUserId(getInfo("UserID"));
    }, []);

    useEffect(() => {
        //getaccounts, sps and bens
        GetAccounts().then((response) => {
            if (Object.keys(response).length === 0) if (state !== 2) setState(1);
            Object.keys(response).forEach((key, index) => {
                GetAccount(key).then((accDetails) => {
                    if (Object.keys(accDetails.ServiceProviders).length === 0 && Object.keys(accDetails.Beneficiaries).length === 0) if (state !== 2) setState(1);
                    Object.keys(accDetails.Beneficiaries).forEach((k, i) => {
                        GetBeneficiary(k).then((benDetails) => {
                            if (state !== 2) setState(1);
                            setBeneficiaryList((beneficiaryList) => ({ ...beneficiaryList, [k]: benDetails }));
                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })
                        GetSentRequestsFromBeneficiary(k, true).then((resp) => {
                            setRequestsForBen((requestsForBen) => ({ ...requestsForBen, [k]: resp }))
                        }).catch(err => console.log('Err getting request for beneficiary', err))
                    })
                    Object.keys(accDetails.ServiceProviders).forEach((k, i) => {
                        GetServiceProvider(k).then((spDetails) => {
                            if (state !== 2) setState(1);
                            setServiceProviderList((serviceProviderList) => ({ ...serviceProviderList, [k]: spDetails }));
                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })
                        GetSentRequestsFromServiceProvider(k, true).then((resp) => {
                            setRequestsForSP((requestsForSP) => ({ ...requestsForSP, [k]: resp }))
                        }).catch(err => console.log('Err getting request for service provider', err))

                        GetSharesWithServiceProvider(k).then((resp) => {
                            if (resp.length > 0) {
                                resp.forEach((ele) => {
                                    if (ele.securedcontentID === "")
                                        setTokens((tokens) => ({ ...tokens, [ele.addressID]: ele.token }));
                                    else
                                        setTokens((tokens) => ({ ...tokens, [ele.securedcontentID]: ele.token }));
                                })

                            }
                        }).catch(err => console.log('Error fetching shares with sp', err))
                    })
                })
            })
        })
        return () => clearTimeout(timer);
    }, [state, timer]);

    function handleAccordionClick(ev) {
        let dv, icon;
        if (ev.target.dataset && ev.target.dataset.accordionTarget) {
            dv = document.getElementById(ev.target.dataset.accordionTarget);
            icon = ev.target.querySelector("svg");
        } else {
            const button = ev.target.closest("button");
            dv = document.getElementById(button.dataset.accordionTarget);
            icon = button.querySelector("svg");
        }
        if (dv.classList.contains("hidden")) {
            localStorage.setItem(document.location.hostname + '.sprequests.accordion.' + dv.id, 'block')

            dv.classList.add("block");
            dv.classList.remove("hidden");
            icon.classList.remove("rotate-180");
        } else {
            localStorage.setItem(document.location.hostname + '.sprequests.accordion.' + dv.id, 'hidden')

            dv.classList.add("hidden");
            dv.classList.remove("block");
            icon.classList.add("rotate-180");
        }
    }

    const DeleteRequestForSP = (request, spid) => {
        DeleteRequest(request.id)
            .then((response) => {
                // Set the retrieved individual in the state
                //window.alert("Deleted Request " + request.id);
                setShowAlert(true);
                setAlertText("Deleted request " + request.id);
                timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                GetSentRequestsFromServiceProvider(spid, true).then((resp) => {
                    setRequestsForSP((requestsForSP) => ({ ...requestsForSP, [spid]: resp }))
                }).catch(err => console.log('Err getting request for service provider', err))
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
                setShowAlert(true);
                setAlertText("Error fetching individual");
            });
    };

    const DeleteRequestForBen = (request, benid) => {
        DeleteRequest(request.id)
            .then((response) => {
                // Set the retrieved individual in the state
                //window.alert("Deleted Request " + request.id);
                setShowAlert(true);
                setAlertText("Deleted request " + request.id);
                timer = setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                GetSentRequestsFromBeneficiary(benid, true).then((resp) => {
                    setRequestsForBen((requestsForBen) => ({ ...requestsForBen, [benid]: resp }))
                }).catch(err => console.log('Err getting request for beneficiary', err))
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
                setShowAlert(true);
                setAlertText("Error fetching individual");
            });
    };

    const sendReminderEmail = (req) => {
        GetRequestDetails(req.id).then((request) => {

            const origin = window.location.origin;
            const to = encodeURIComponent(request.toIndividualEmail);
            const subject = encodeURIComponent("Reminder: Share content on JustWhere (" + origin + ")")
            const body = encodeURIComponent(
                "Hi,\nI have sent a request to share content on JustWhere, can you please respond to the request " + origin + "/#requests?\n\n" +
                request.tags?.note?.Value +
                "\n\nI'm using JustWhere(" + origin + ") to maintain my content. It will be great if you share content on JustWhere(" + origin + ")"
            );
            var link = isMobile() ? "mailto:" + to + "?subject=" + subject + "&body=" + body :
                "https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=" + to + "&su=" + subject + "&body=" + body;
            //window.open(link, "_blank");
            const a = document.createElement("a"); a.setAttribute('href', link); a.setAttribute('target', '_blank'); a.click();
        }).catch((err) => {
            console.log('error fetching request details');
        })
    };

    const saveCurrentState = (e) => {
        let x = e.screenX;  // Horizontal
        let y = e.screenY;  // Vertical
        localStorage.setItem(document.location.hostname + ".sprequests.x", x);
        localStorage.setItem(document.location.hostname + ".sprequests.y", y);
    }


    return userId ? (
        <div>
            <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
                {state === 0 ? "Loading..." : Object.keys(requestsForSP).length === 0 && Object.keys(requestsForBen).length === 0 ?
                    (<div className="w-full h-full flex p-2 items-start justify-start text-blue-300">
                        <p className="text-start">
                            No requests yet! When people share content with you, their requests will appear here. Keep adding and updating your profile so others can start sharing with you.
                        </p>
                    </div>) : <>                <h1 className="py-2 bold-text-input">Content Sharing Requests</h1>
                        {showAlert ? (
                            <div
                                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                                role="alert"
                                style={{ display: showAlert ? "block" : "none" }}
                            >
                                <span class="text-sm">{alertText} </span>
                            </div>
                        ) : <></>}
                        {Object.keys(requestsForSP).length > 0 ? Object.keys(requestsForSP).map((spid) => {
                            return <><h2 id="accordion-collapse-heading-1">
                                <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                                    <span>Requests Sent by {serviceProviderList[spid]?.tags?.name?.Value} {requestsForSP[spid].length}</span>
                                    <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-1") === null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" : "w-3 h-3 shrink-0"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                    </svg>
                                </button>
                            </h2>
                                <div id="accordion-collapse-body-1" class={localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-1") === null ? "hidden" : localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-")} aria-labelledby="accordion-collapse-heading-2">
                                    <div class="p-5 border border-gray-700 ">
                                        <ul class="w-full divide-y divide-gray-700">
                                            {requestsForSP[spid].sort((a, b) => { return b.modifiedAt - a.modifiedAt }).map((Arequest) => {
                                                return (
                                                    <li class="py-2 ">
                                                        <div className=" grid grid-cols-1 md:grid-cols-2 items-center justify-between">
                                                            <div className="order-2 md:order-1 flex-1"><p class="py-1 text-sm md:font-medium"><p>ID:</p><span className="text-gray-400 text-xs md:text-sm font-extralight" id={"req_" + Arequest.id}>{Arequest.id}</span><CopyToClipboard elId={"req_" + Arequest.id} /></p></div>
                                                            <div class="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:justify-end rtl:space-x-reverse">
                                                                <RequestStatus status={Arequest.status} />
                                                                <span className="py-1 text-sm md:font-medium" title={ConvertUnixTimestampToDateString(Arequest.modifiedAt)}>
                                                                    {dayjs().to(ConvertUnixTimestampToDateString(Arequest.modifiedAt))}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <div className="w-full grid grid-cols-2 justify-around self-start">
                                                                <p class="py-1 text-sm md:font-medium">To: {Arequest.toIndividualEmail}</p>
                                                                <blockquote class="py-3 text-xs italic font-semibold text-white">
                                                                    <p className="truncate">{Arequest.tags?.note?.Value}
                                                                    </p> </blockquote>

                                                                {Arequest.status === "ACCEPTED" ? (
                                                                    Arequest.requestType === "SHARE_ADDRESS" ?
                                                                        <Link className="py-3" to={`/viewrequestaddress-details/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests", tokens: tokens }}>
                                                                            <button className="button-style" title="Show Content" onClick={saveCurrentState}>
                                                                                <AiOutlineProfile title="Show Content" className="inline-icon" />
                                                                                Show Content
                                                                            </button>
                                                                        </Link>
                                                                        : Arequest.requestType === "SHARE_STREAM" ?
                                                                            <Link className="py-3" to={`/documentdetails/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests", tokens: tokens }}>
                                                                                <button className="button-style" title="Show Document" onClick={(e) => { saveCurrentState(e) }}>
                                                                                    <AiOutlineProfile title="Show Document" className="inline-icon" />
                                                                                    Show Document
                                                                                </button>
                                                                            </Link>
                                                                            :
                                                                            <Link className="py-3" to={`/viewsecurecontent/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests", tokens: tokens }}>
                                                                                <button className="button-style" title="Show Content" onClick={(e) => { saveCurrentState(e) }}>
                                                                                    <AiOutlineProfile title="Show Content" className="inline-icon" />
                                                                                    Show Content
                                                                                </button>
                                                                            </Link>
                                                                ) : dayjs().diff(dayjs(ConvertUnixTimestampToDateString(Arequest.modifiedAt)), "hour") > 24 ? (
                                                                    <Link className="py-3">
                                                                        <button formMethod="dialog" formTarget="top" id="sendReminder" onClick={(e) => { sendReminderEmail(Arequest); saveCurrentState(e); }}
                                                                            className="button-style"
                                                                        >
                                                                            <AiOutlineMail title="Send Reminder" className="inline-icon" />
                                                                            Send Reminder
                                                                        </button></Link>
                                                                ) : (<p> </p>
                                                                )}
                                                                <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                                                                    <button formMethod="dialog" formTarget="top" id="deleterequest" onClick={() => DeleteRequestForSP(Arequest, spid)} className="bg-slate-333 py-1 hover:bg-555">
                                                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div></>
                        })
                            : <></>
                        }
                        {Object.keys(requestsForBen).length > 0 ? Object.keys(requestsForBen).map((benid) => {
                            return <><h2 id="accordion-collapse-heading-2">
                                <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
                                    <span>Requests Sent by {beneficiaryList[benid]?.tags?.name?.Value} {requestsForBen[benid].length}</span>
                                    <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-2") === null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" : "w-3 h-3 shrink-0"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                    </svg>
                                </button>
                            </h2>
                                <div id="accordion-collapse-body-2" class={localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-2") === null ? "hidden" : localStorage.getItem(document.location.hostname + ".sprequests.accordion.accordion-collapse-body-2")} aria-labelledby="accordion-collapse-heading-2">
                                    <div class="p-5 border border-gray-700 ">
                                        <ul class="w-full divide-y divide-gray-700">
                                            {requestsForBen[benid].sort((a, b) => { return b.modifiedAt - a.modifiedAt }).map((Arequest) => {
                                                return (
                                                    <li class="py-2 ">
                                                        <div className=" grid grid-cols-1 md:grid-cols-2 items-center justify-between">
                                                            <div className="order-2 md:order-1 flex-1"><p class="py-1 text-sm md:font-medium"><p>ID:</p><span className="text-gray-400 text-xs md:text-sm font-extralight" id={"req_" + Arequest.id}>{Arequest.id}</span><CopyToClipboard elId={"req_" + Arequest.id} /></p></div>
                                                            <div class="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:justify-end rtl:space-x-reverse">
                                                                <RequestStatus status={Arequest.status} />
                                                                <span className="py-1 text-sm md:font-medium" title={ConvertUnixTimestampToDateString(Arequest.modifiedAt)}>
                                                                    {dayjs().to(ConvertUnixTimestampToDateString(Arequest.modifiedAt))}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <div className="w-full grid grid-cols-2 justify-around self-start">
                                                                <p class="py-1 text-sm md:font-medium">To: {Arequest.toIndividualEmail}</p>
                                                                <blockquote class="py-3 text-xs italic font-semibold text-white">
                                                                    <p className="truncate">{Arequest.tags?.note?.Value}
                                                                    </p> </blockquote>

                                                                {Arequest.status === "ACCEPTED" ? (
                                                                    Arequest.requestType === "SHARE_ADDRESS" ?
                                                                        <Link className="py-3" to={`/viewrequestaddress-details/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests" }}>
                                                                            <button className="button-style" title="Show Content" onClick={saveCurrentState}>
                                                                                <AiOutlineProfile title="Show Content" className="inline-icon" />
                                                                                Show Content
                                                                            </button>
                                                                        </Link>
                                                                        : Arequest.requestType === "SHARE_STREAM" ?
                                                                            <Link className="py-3" to={`/documentdetails/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests" }} >
                                                                                <button className="button-style" title="Show Document" onClick={(e) => { saveCurrentState(e) }}>
                                                                                    <AiOutlineProfile title="Show Document" className="inline-icon" />
                                                                                    Show Document
                                                                                </button>
                                                                            </Link>
                                                                            :
                                                                            <Link className="py-3" to={`/viewsecurecontent/${Arequest.id}`} state={{ previousPage: "/serviceproviderrequests" }} >
                                                                                <button className="button-style" title="Show Content" onClick={(e) => { saveCurrentState(e) }}>
                                                                                    <AiOutlineProfile title="Show Content" className="inline-icon" />
                                                                                    Show Content
                                                                                </button>
                                                                            </Link>
                                                                ) : dayjs().diff(dayjs(ConvertUnixTimestampToDateString(Arequest.modifiedAt)), "hour") > 24 ? (
                                                                    <Link className="py-3">
                                                                        <button formMethod="dialog" formTarget="top" id="sendReminder" onClick={(e) => { sendReminderEmail(Arequest); saveCurrentState(e); }}
                                                                            className="button-style"
                                                                        >
                                                                            <AiOutlineMail title="Send Reminder" className="inline-icon" />
                                                                            Send Reminder
                                                                        </button></Link>
                                                                ) : (<p> </p>
                                                                )}
                                                                <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                                                                    <button formMethod="dialog" formTarget="top" id="deleterequest" onClick={() => DeleteRequestForBen(Arequest, benid)} className="bg-slate-333 py-1 hover:bg-555">
                                                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>




                                                    </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div></>
                        })
                            : <></>
                        }
                    </>}
            </div>
        </div>)
        : <Login />
}