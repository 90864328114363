import React, { useEffect, useState }from "react";

import { GetShares, SearchBeneficiaries, SearchSP,getInfo } from "../utilities/calls";
import { Link } from "react-router-dom";
import "./PromoBusinesses.css";

import { FaHandshake } from "react-icons/fa";


const refresh_frequency = process.env.REACT_APP_PROMO_BUSINESS_FREQUENCY || 10000;

const hashCategoryToColor = (category) => {
    let hash = 5381;
    for (let i = 0; i < category.length; i++) {
        hash = (hash * 33) ^ category.charCodeAt(i);
    }
    hash = Math.abs(hash);
    const r = (hash & 0xFF0000) >> 16;  // Extract the red component
    const g = (hash & 0x00FF00) >> 8;   // Extract the green component
    const b = hash & 0x0000FF;          // Extract the blue component
    const vibrancyFactor = 0.8;  // Control vibrancy (higher values = more vivid)
    const adjustedR = Math.min(255, (r * vibrancyFactor));  // Add a boost to ensure no low values
    const adjustedG = Math.min(255, (g * vibrancyFactor));
    const adjustedB = Math.min(255, (b * vibrancyFactor));
    return `rgb(${Math.round(adjustedR)},${Math.round(adjustedG)},${Math.round(adjustedB)})`;
};

const PromoBusiness = ({ business,connected }) => {
    return <Link name="promo_business" to={business.isBen ? `/AddBeneficiaryProfile/${business.id}` : `/AddServiceProviderProfile/${business.id}`} state={{ showConnect: true, readOnly: true, previousPage: `/` }} className={business.isBen ? ` relative shadow-md rounded-md border border-[#067da1]` : ` relative shadow-md rounded-md border border-[#B87333]`}>
        <button className="bg-gray-950 pb-3 px-1 pt-1  flex flex-col items-center text-center w-36 shadow-md rounded-md   hover:bg-gray-700 hover:scale-105 active:scale-95 transition-all duration-1000 ease-in-out hover:shadow-lg active:shadow-inner border-l-4" style={{"border-color" : hashCategoryToColor(business?.tags?.categories?.Value|| "Default")}}>
            <div class="absolute top-1 right-1 z-10">
                {connected ? <FaHandshake className=" text-md text-green-400"  style={{ width: "1.2em" }}/>
                    : <></>}
            </div>
        {business?.tags?.logourl?.Value ? (<div className="rounded-sm w-full h-16 flex items-center justify-center mb-2 relative overflow-hidden">
                  <img
                        src={business?.tags?.logourl?.Value} 
                        alt={business?.tags?.name?.Value} className="max-h-full max-w-full object-contain align-bottom"
                    />
                 </div>) : (
                    <div className="rounded-sm w-full h-16 flex items-center justify-center mb-2 relative overflow-hidden">
                    <div className="w-12 h-12  bg-gradient-to-r from-indigo-400 to-cyan-400 flex items-center align-bottom justify-center rounded-full text-gray-800 text-lg font-bold">
                        {business?.tags?.name?.Value ? business?.tags?.name?.Value.charAt(0).toUpperCase() : ""}
                    </div></div>
                )}
            
            <div className="text-white text-sm font-semibold w-full flex flex-wrap items-center justify-center h-12 px-1 mb-1 word-wrap">
                {business?.tags?.name?.Value} 
            </div>
        </button></Link>
};

const fetchBusinesses = async () => {
    try {
        const bens = await SearchBeneficiaries("");
        const sps = await SearchSP("");
        const data = [...sps.filter(sp => sp?.tags?.name?.Value && sp?.tags?.name?.Value.trim() !== "")];
        bens.forEach(ben => {
            if(ben?.tags?.name?.Value && ben?.tags?.name?.Value.trim() !== ""){
                ben.isBen = true;
                data.push(ben);
            }
        });
        return data || [];
    } catch (error) {
        console.error("Error fetching businesses", error);
        return [];
    }
};

const getRandomBusinesses = (businessList, count = 5) => {
    const uniqueBusinesses = Array.from(new Map(businessList.map(b => [b?.tags?.name?.Value, b])).values());
    const shuffled = [...uniqueBusinesses].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
};

const isConnected = (business, shares) => {
    if(business.isBen){
        return shares.findIndex((share) => share.beneficiaryID === business.id) > -1
    } else {
        return shares.findIndex((share) => share.serviceProviderID === business.id) > -1
    }
}

const PromoBusinesses = () => {
    const [businesses, setBusinesses] = useState([]);
    const [allBusinesses, setAllBusinesses] = useState([]);
    const [shares, setShares] = useState([]);
    const [isFlipping, setIsFlipping] = useState();

    useEffect(() => {
        const loadBusinesses = async () => {
            const fetchedBusinesses = await fetchBusinesses();
            setAllBusinesses(fetchedBusinesses);
            setBusinesses(getRandomBusinesses(fetchedBusinesses, window.innerWidth < 768 ? 2 : 5));
        };
        
        loadBusinesses();
    }, []);

    useEffect(() => {
        GetShares(getInfo("IndividualID")).then((shares) => {
            setShares(shares);
        });
    }, [] );

    useEffect(() => {
        if (allBusinesses.length === 0) return;
        
        const interval = setInterval(() => {
            setIsFlipping(true);
            setTimeout(() => {
                setIsFlipping(false);
                setBusinesses(getRandomBusinesses(allBusinesses, window.innerWidth < 768 ? 2 : 5));
            }, 1000);
        }, refresh_frequency);       
        return () => clearInterval(interval);
    }, [allBusinesses]);

    useEffect(() => {
        const collection = document.getElementsByName("promo_business");
        const container = document.getElementById('promo_container');
        
        for (let i = 0; i < collection.length; i++) {
            if (isFlipping) {
                collection[i].classList.add('shake','fade-out');
                collection[i].classList.remove('fade-in');
            } else {
                collection[i].classList.remove('shake', 'fade-out');
                collection[i].classList.add('fade-in');
            }
        }
        setTimeout(() => {
        if(isFlipping){
            container.classList.remove('slide-in');
            container.classList.add('slide-out');
        } else {
            container.classList.remove('slide-out');
            container.classList.add('slide-in');
        }}, 500);
    },[isFlipping])

    return (
         <section className="p-4 text-center flex flex-col items-center rounded-lg shadow-lg mx-3 md:mx-12">
        <div className="mb-2">
            <div className="text-lg font-bold">Discover Top Businesses</div>
        </div>
        <div id="promo_container" className="flex justify-center gap-2 mt-2 flex-nowrap overflow-x-auto w-full ">
            {businesses.map((business) => (
                <PromoBusiness key={business.id} business={business} connected={isConnected(business,shares )} isFlipping={isFlipping} />
            ))}
        </div>
    </section>

  );
};

export default PromoBusinesses;
