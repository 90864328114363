import { GetAdminUserIdsForBeneficiary, GetAdminUserIdsForServiceProvider, AssignAdminForBeneficiary, AssignAdminForServiceProvider, RemoveAdminFromBeneficiary, RemoveAdminFromServiceProvider, GetJustWhereUsers, getInfo } from "../utilities/calls";
import { useEffect, useState, useCallback } from "react";
import { IoPersonRemove, IoPersonAdd } from "react-icons/io5";
import DeleteModal from "./DeleteModal";
import { sanitizeEmail } from "../utilities/functions";

export default function BusinessProfileAdminModal({ serviceProvider, beneficiary, onClose }) {
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [admins, setAdmins] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [confirmationText, setConfirmationText] = useState();
    const [selectedUserId, setSelectedUserId] = useState();
    const [inputUserId, setInputUserId] = useState("");
    const loggedInUser = (decodeURIComponent(getInfo("UserID")));


    const updateAdminsList = useCallback(() => {
        if (serviceProvider) {
            GetAdminUserIdsForServiceProvider(serviceProvider.id).then(adminsArr => {
                if (adminsArr && adminsArr.length > 0) setAdmins(adminsArr)
            }).catch(err => {
                console.log('Error retrieving admins for the service provider', err)
                setShowAlert(true);
                setAlertText("Error while loading admin list, please try again.");
            })
        } else if (beneficiary) {
            GetAdminUserIdsForBeneficiary(beneficiary.id).then(adminsArr => {
                if (adminsArr && adminsArr.length > 0) setAdmins(adminsArr)
            }).catch(err => {
                console.log('Error retrieving admins for the beneficiary', err)
                setShowAlert(true);
                setAlertText("Error while loading admin list, please try again.");
            })
        }
    }, [beneficiary, serviceProvider]);

    useEffect(() => {
        updateAdminsList();
     }, [updateAdminsList]);

    const handleRemoveClick = (userId) => {
        setSelectedUserId(userId);
        setAlertText("");
        setShowAlert(false);
        let confText = "Are you certain you wish to Remove " + userId + " as admin of the ";
        if (serviceProvider) { confText += "service provider?" } else if (beneficiary) { confText += "beneficiary" }
        setConfirmationText(confText);
        setShowDeleteDlg(true);
    }

    const removeAdmin = () => {
        setShowDeleteDlg(false);

        if (serviceProvider) {
            RemoveAdminFromServiceProvider(serviceProvider.id, selectedUserId).then(resp => {
                setAdmins([]);
                updateAdminsList();
            }).catch(err => {
                console.log('Error while removing admin from service provider', err);
                setShowAlert(true);
                setAlertText("Error while removing admin from service provider, please try again.");
            })
        } else if (beneficiary) {
            RemoveAdminFromBeneficiary(beneficiary.id, selectedUserId).then(resp => {
                setAdmins([]);
                updateAdminsList();
            }).catch(err => {
                console.log('Error while removing admin from beneficiary', err);
                setShowAlert(true);
                setAlertText("Error while removing admin from beneficiary, please try again.");
            })
        }
    }

    const addAdmin = () => {
        
        if(!inputUserId || inputUserId.trim().length === 0){
            setShowAlert(true);
            setAlertText("Please enter the User Id you want to add as an admin.");
            return;
        }
        GetJustWhereUsers([inputUserId]).then((resp) => {
            if(resp.validUserIds?.length === 0){
              const updatedUserEmail=sanitizeEmail(inputUserId)
              GetJustWhereUsers([updatedUserEmail]).then((resp)=>{
                 if(resp.validUserIds?.length > 0) {
                    setShowAlert(true);
                    setAlertText(inputUserId + " is not a valid JustWhere User but " + resp.validUserIds[0] + " is on JustWhere, you can use this userID for Add admin.")
                 } else {
                    setShowAlert(true);
                    setAlertText( inputUserId + " is not a valid JustWhere User.");
                 }
                })
            } else {
                if(serviceProvider){
                    AssignAdminForServiceProvider(serviceProvider.id, inputUserId).then(resp => {
                        setAdmins([]);
                        updateAdminsList();
                        setInputUserId("");
                    }).catch(err => {
                        console.log('Error while assigning admin for service provider', err);
                        setShowAlert(true);
                        setAlertText("Error while assigning admin for service provider, please try again.");
                    })
                } else if(beneficiary){
                    AssignAdminForBeneficiary(beneficiary.id, inputUserId).then(resp => {
                        setAdmins([]);
                        updateAdminsList();
                        setInputUserId("");
                    }).catch(err => {
                        console.log('Error while assigning admin for beneficiary', err);
                        setShowAlert(true);
                        setAlertText("Error while assigning admin for beneficiary, please try again.");
                    })
                }
            }
        })
    }

    const handleInputChange = (e) => {
        setInputUserId(e.target.value);
    }


    return <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">
        <div className='w-11/12 max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>
            <div className="py-3 px-2">
                <button className="absolute top-2 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={onClose}>X</button>
                
                <DeleteModal confirmationText={confirmationText} deleteLabel="Remove" onDeleteFn={removeAdmin} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

                <h1 className="form-label">
                    {serviceProvider?.tags?.name?.Value || beneficiary?.tags?.name?.Value} Admins
                </h1>
                <h1 className="form-label">
                    {serviceProvider?.id || beneficiary?.id}
                </h1>
                <div class="relative w-full p-2">
                    <input type="input" id="userID" class="block w-full form-input" style={{"margin-bottom": "0", "padding-top":"0.5rem"}} placeholder="Enter User ID" value={inputUserId} onChange={handleInputChange}/>
                    <button type="submit" onClick={addAdmin} class="text-white absolute end-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm p-1 me-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" style={{ bottom: ".8rem" }}><IoPersonAdd className="inline-icon" /> Add Admin</button>
                </div>
                <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative w-full" role="alert" style={{ display: showAlert ? "block" : "none" }} >
                <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button><span class="text-sm">{alertText} </span>
                </div>
                <div class="w-full border border-gray-700 border-x-0 max-h-48 p-4 overflow-y-auto ">
                    <ul class="w-full divide-y divide-gray-700">
                        {
                            admins.sort((a, b) => { return a?.toLowerCase().localeCompare(b?.toLowerCase()) }).map((admin, index) => {
                                return <li class="pt-1 pb-3 sm:pb-4">
                                    <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                        <div class="flex-1">
                                            <p class="text-base font-semibold truncate">
                                                {admin} {admin === loggedInUser ? " (you)" : ""}
                                            </p>
                                        </div>
                                        <div class="inline-flex items-center text-white">
                                            {(admins.length > 1 && admin !== loggedInUser)  ? <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleRemoveClick(admin)} className="bg-slate-333 py-1 hover:bg-555">
                                                <IoPersonRemove title="Remove Admin" className="inline-icon" />
                                            </button> : <></>}
                                        </div>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
}