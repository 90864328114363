import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { renderField } from "../utilities/functions";
import { AiOutlineProfile } from "react-icons/ai";



export default function CreateBeneficiarySP({ show, showFn }) {
    const [fullAddress, setFullAddress] = useState();

    useEffect(() => { setFullAddress(false); }, []);
    const navigate = useNavigate();


    const addServiceProvider = () => {
       let spid = "00000000-0000-0000-0000-000000000000";
        if (fullAddress) navigate('/AddBeneficiaryProfile/' + spid, {state : {previousPage:"/serviceProviders"}})
            else navigate('/AddServiceProviderProfile/' + spid, {state : {previousPage:"/serviceProviders"}})
        showFn(false);
    }

    const cancelFn = () => {
        showFn(false)
    }

    const handleInputChange = (e) => {
        setFullAddress(e.target.checked);
    }

    return show === true ?
        <div id="beneSPModal" className="fixed flex z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
            <div class="flex-1 p-4 w-full max-w-md h-full md:h-auto">
                <div class=" p-4 text-center rounded-lg sm:p-5" className="form-container">
                    <h1 className="py-2 form-label">
                        <AiOutlineProfile className="inline-icon" />
                        Create Service Provider
                    </h1>

                    {renderField("This service needs full Address View (you will be a beneficiary)", "fullAddr", "checkbox", fullAddress, handleInputChange)}

                    <span class="py-2 text-sm ">{fullAddress === true ? "" : "This is a Service provider; partial address is adequate."} </span>
                    <div class="flex justify-center items-center space-x-4 py-2 ">
                        <button onClick={addServiceProvider} type="submit" className="button-style positive-action">
                            Create
                        </button>
                        <button onClick={cancelFn} type="button" className="button-style">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        : <></>
}