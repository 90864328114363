import React, { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  FetchIndividual,
  getInfo,
  GetSharesForAddress,DeleteAddress, GetServiceProvider, GetBeneficiary
  } from "../utilities/calls";
import { AiOutlineProfile } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import Login from "./Login";
import DeleteModal from "./DeleteModal";
import CopyToClipboard from "./CopyToClipboard";
import { FaShareAlt } from "react-icons/fa";
import ShareModal from "./ShareModal";
import { displayDate, renderSharesWith, getSharesWith } from "../utilities/functions";



const AddressList = ({contenttype, addressCount, show}) => {
  
  const [individual, setIndividual] = useState({});
  const [sharedCounts, setSharedCounts] = useState({});
  const [userId, setUserId] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [showDeleteDlg, setShowDeleteDlg] = useState();
  const[confirmationText, setConfirmationText] = useState();
  const[currAddressId, setCurrAddressId] = useState();
  const individualid = getInfo("IndividualID");
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [sharesWith, setSharesWith] = useState({});
  const [spDetails, setSpDetails] = useState({});
  const [benDetails, setBenDetails] = useState({});
 
  let timer = 0;

  useLayoutEffect(() => {
    setUserId(getInfo("UserID"));
    setShowDeleteDlg(false);
  }, []);

  const GetAddressses = useCallback(() => {

    FetchIndividual(individualid).then((individualData) => {
      let referredSpIds = [];
      let referredBenIds = [];
  
      // Set the retrieved individual in the state
      const alladdresses = individualData?.addresses || {};
      addressCount(Object.keys(alladdresses || {}).length)
      const fetchCounts = async (addresses) => {
        const sharesPromises = Object.keys(addresses).map((id) =>
          GetSharesForAddress(id)
            .then((response) => {
              let sharesWithArr = getSharesWith(response, referredSpIds, referredBenIds)
              referredBenIds.forEach(benId => GetBeneficiary(benId).then((el) => setBenDetails((benList) => ({...benList, [benId] : el}))).catch((err) => console.log(err)))
              referredSpIds.forEach(spId => GetServiceProvider(spId).then((el) => setSpDetails((spList) => ({...spList, [spId] : el}))).catch((err) => console.log(err)))

              return { addressid: id, count: response.length, sharesWith : sharesWithArr  };

            })
            .catch((error) => {
              console.error(`Error shares for ID ${id}:`, error);
              setShowAlert(true);
              setAlertText("Error fetching shares");
            })
        );
        Promise.all(sharesPromises)
          .then((details) => {
            const cmap = {};
            const sharesMap = {};
            details.forEach(
              (detail) => {cmap[detail.addressid] = detail.count;
                sharesMap[detail.addressid] = detail.sharesWith
              }
            );
            setSharedCounts(cmap);
            setSharesWith(sharesMap);

          })
          .catch((error) => {
            console.error("Error setting record details:", error);
            setShowAlert(true);
            setAlertText("Error setting record details");
          });
      };
      setIndividual(individualData);
      if (Object.keys(individualData?.addresses || {}).length > 0) {
        fetchCounts(alladdresses);
      }
    });
  
  },[addressCount, individualid]);

  useEffect(() => {
    GetAddressses();
    return () => clearInterval(timer);
  }, [GetAddressses, timer]);

  const deleteAddress = () => {
    setShowDeleteDlg(false);
    const ownertoken = getInfo("Ownertoken");

    
    DeleteAddress(currAddressId, individualid, ownertoken)
    .then((response) => {
      setShowAlert(true);
      setAlertText("Address was successfully deleted." );
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      GetAddressses();
    })
    .catch((error) => {
      console.error("Error deleting an address", error);
      setShowAlert(true);
      setAlertText("Error deleting an address.");
    });
  
  }

  const handleDeleteClick = (addressId) => {
    const scount = sharedCounts[addressId];

    setConfirmationText("Are you certain you wish to delete this address? It has been shared " + scount + " times. Deleting it will Remove those shares. Please confirm ...");
    setShowDeleteDlg(true);
    setCurrAddressId(addressId);
  }

  const handleShareContentClick= (addr) => {
    setSelectedAddress(addr)
    setShowShareModal(true);
}
const showSharedWithModal = (addrid) => {
  var el = document.getElementById("ad_" + addrid);
  el.classList.remove("invisible");
}

const hideSharedWithModal = (addrid) => {
  document.getElementById("ad_" +addrid).classList.add('invisible')
}

  return userId ? (
    show ?
    <div className="px-1 md:px-4 py-1 max-w-screen-xl mx-auto items-center">
    <DeleteModal confirmationText={confirmationText} deleteLabel="Delete" onDeleteFn={deleteAddress} onCancelFn={()=>{setShowDeleteDlg(false)}} show={showDeleteDlg}/>

      {individual.id ? (
        
          <div >
            <Link
              className=""
              to={{ pathname: "/AddAddressDetails" }}
              state={{ tags: individual.tags, previousPage: "/securedContents", "contenttype" : contenttype }}
            >
              <button className="button-style hover:bg-555">
                <AiOutlineProfile className="inline-icon" />
                Add Address
              </button>
            </Link>
            <div >
              <ul class="w-full divide-y divide-gray-700 py-3">
              {Object.keys(individual.addresses || {}).sort((a, b) => {return individual.addresses[a].tags?.atag?.Name.toLowerCase().localeCompare(individual.addresses[b].tags?.atag?.Name.toLowerCase())}).map((addressId, index) => {
                    const address = individual.addresses[addressId];
                    const scount = sharedCounts[addressId];
                    const swith = sharesWith[addressId]
                    return <li class="py-3 sm:py-2">
                      
                       <div class="flex items-center space-x-4 rtl:space-x-reverse">
                          
                          <div class="flex-1">
                             <Link class="font-semibold truncate" to={`/address-details/${address.id}`} state={{"contenttype": contenttype, previousPage: '/securedContents'}}>
                            {`${address.tags?.atag?.Name || "No Name"}`}
                          </Link>
                          
                          {renderSharesWith("ad_", address.id, scount, swith,showSharedWithModal, hideSharedWithModal, spDetails, benDetails)}

                          </div>
                          <div class="flex-shrink-0 md:flex-1">
                           

                          </div>
                          <div class="inline-flex items-center text-white">
                          <button formMethod="dialog" formTarget="top" id="deleteAddress" onClick={() => handleDeleteClick(addressId)} className="bg-slate-333 py-1 hover:bg-555">
                            <BsFillTrash3Fill title="Delete" className="inline-icon" />
                          </button>
                          
                          </div>
                       </div>
                       <p className="mt-1 text-xs text-gray-400">{address.updated ? "Updated " + displayDate(address.updated) : ""}  </p>

                       {address.updated && address.created && address.updated !== address.created ? <p className="text-xs text-gray-400">{address.created ? "Created " + displayDate(address.created) : ""}</p> : <></>}

                       <p class="mt-1 text-sm text-gray-200">
                                <span id={"addr_guid_" +index }>{address.id}</span><CopyToClipboard elId={"addr_guid_" +index }/>
                             </p>
                          <button formMethod="dialog" formTarget="top" id="offerAddress" onClick={() => handleShareContentClick(address)} className="ps-2 text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1">
                             <FaShareAlt className="inline-icon"  style={{height: "1rem" , width: "1rem" }}/>Share
                          </button>
                    </li>
                      
                  })
                }</ul>
            </div>
            {showShareModal && <ShareModal onClose={() => {
              GetAddressses();
              setShowShareModal(false);
            }} address={selectedAddress} title={selectedAddress.tags?.atag?.Name || ""}/>}

          </div>
        
      ) : (
        <div>
          {showAlert ? (
            <div
              class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
              role="alert"
              style={{ display: showAlert ? "block" : "none" }}
            >                <button className="absolute top-2 right-1 text-lg font-normal text-gray-700 -translate-x-2 " onClick={()=> {setShowAlert(false)}}>X</button>
              <span class="text-sm">{alertText} </span>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      )}
    </div>
    : <></>
  ) : (
    <Login/>
  );
};

export default AddressList;
